import { CaseModel } from '@app/core/models/case.model';
import { OptionModel } from '@app/core/models/option.model';
import {
  CASE_ORIGIN_CODE,
  STATUS_CODE_MAP,
  SUPPORT_TYPES,
} from '@app/shared/app.constants';
import { CaseState } from '@app/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectCaseSlice = createFeatureSelector<CaseState>('case');

export const getAllCases = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.allCases
);

export const getSupportCaseTypes = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseTypes
);

export const getCaseDetailsMap = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseDetailsMap
);

export const getCaseDetailsError = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseDetailsError
);

export const getFormattedCases = createSelector(
  getSupportCaseTypes,
  getAllCases,
  getCaseDetailsMap,
  (caseTypes, allCases, caseDetailsMap) => {
    if (allCases && caseTypes) {
      const caseTypeFilter = caseTypes.map(caseType => caseType.id);

      // filter cases by case type and filter out Corrigo Pro with Fiannce
      allCases = allCases.filter(
        (caseModel: CaseModel) =>
          (caseModel.caseOriginCode !== CASE_ORIGIN_CODE.CORRIGO_PRO ||
            caseModel.caseTypeCode.toString() !== SUPPORT_TYPES.FINANCE) &&
          caseTypeFilter.includes(caseModel.caseTypeCode.toString())
      );

      const formattedCases: CaseModel[] = [];

      // add string values for each case
      for (let i = 0; i < allCases.length; i += 1) {
        formattedCases.push({
          ...allCases[i],
          stateCodeString: allCases[i].stateCode === 0 ? 'Open' : 'Resolved',
          statusCodeString: STATUS_CODE_MAP[allCases[i].statusCode],
          caseTypeCodeString: caseTypes.find(
            caseType => caseType.id === allCases[i].caseTypeCode.toString()
          ).name,
          details: caseDetailsMap[allCases[i].caseNumber],
        });
      }

      return formattedCases;
    }
  }
);

export const getOrderedSupportCaseTypes = createSelector(
  selectCaseSlice,
  (state: CaseState) => {
    if (state.caseTypes.length > 0) {
      const theOrder = [
        SUPPORT_TYPES.MISSED_SERVICE,
        SUPPORT_TYPES.DAMAGED_EQUIPMENT,
        SUPPORT_TYPES.INCIDENTS_ACCIDENTS,
        SUPPORT_TYPES.FINANCE,
        SUPPORT_TYPES.SUSTAINIBILITY,
        SUPPORT_TYPES.REPORTING,
        SUPPORT_TYPES.OTHER,
      ];
      const orderedCaseTypes: OptionModel[] = [];

      for (let i = 0; i < theOrder.length; i += 1) {
        const caseType = state.caseTypes.find(
          caseType => caseType.id === theOrder[i]
        );

        orderedCaseTypes.push(caseType);
      }

      return orderedCaseTypes;
    }
  }
);

export const getSupportCaseStatuses = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseStatuses
);

export const getCreatedCaseNumber = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.createdCaseNumber
);

export const getCaseCreationError = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseCreationError
);

export const getCreatedCaseFollowUpNumber = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.createdCaseFollowUp
);

export const getCaseFollowUpError = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseCreationError
);

export const getCasesTypeSummary = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseTypeSummary
);

export const isCasesLoading = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.casesLoading
);

export const isCaseStatusLoading = createSelector(
  selectCaseSlice,
  (state: CaseState) => state.caseStatusLoading
);
