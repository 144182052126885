import { ContainerDeviceActions } from '@app/store/actions';
import { createReducer, on } from '@ngrx/store';
import { ContainerDeviceResponse } from '@app/core/api/model/containerDeviceResponse';
import { SequoiaDeviceModel } from '@app/core/models/sequoia-device.model';
import { SequoiaFullnessVisualizationModel } from '@app/core/models/sequoia-fullness-visualization.model';

export const containerDevicesFeatureKey = 'containerDevices';

export interface ContainerDevicesState {
  allSequoiaDevices: SequoiaDeviceModel[] | null;
  showSequoiaDevicesLoading: boolean;
  sequoiaLoggedFullness: SequoiaFullnessVisualizationModel[] | null;
  sequoiaForecastedFullness: SequoiaFullnessVisualizationModel[] | null;
  allPelloDevices: ContainerDeviceResponse[] | null;
  showPelloDevicesLoading: boolean;
  demoRequestsuccess: boolean;
}

const initialState: ContainerDevicesState = {
  allSequoiaDevices: null,
  showSequoiaDevicesLoading: false,
  sequoiaLoggedFullness: null,
  sequoiaForecastedFullness: null,
  allPelloDevices: null,
  showPelloDevicesLoading: false,
  demoRequestsuccess: null,
};

export const containerDevicesReducer = createReducer(
  initialState,

  on(ContainerDeviceActions.showPelloDevicesLoading, state => ({
    ...state,
    showPelloDevicesLoading: true,
  })),
  on(ContainerDeviceActions.showSequoiaDevicesLoading, state => ({
    ...state,
    showSequoiaDevicesLoading: true,
  })),
  on(
    ContainerDeviceActions.getSequoiaDevicesSuccess,
    (state, { allSequoiaDevices }) => ({
      ...state,
      allSequoiaDevices: allSequoiaDevices,
      showSequoiaDevicesLoading: false,
    })
  ),
  on(
    ContainerDeviceActions.getSequoiaDeviceLoggedFullnessSuccess,
    (state, { sequoiaLoggedFullness }) => ({ ...state, sequoiaLoggedFullness })
  ),
  on(
    ContainerDeviceActions.getSequoiaDeviceForecastedFullnessSuccess,
    (state, { sequoiaForecastedFullness }) => ({
      ...state,
      sequoiaForecastedFullness,
    })
  ),
  on(ContainerDeviceActions.resetSequoiaDeviceData, state => ({
    ...state,
    sequoiaLoggedFullness: null,
    sequoiaForecastedFullness: null,
  })),
  on(
    ContainerDeviceActions.getPelloDevicesSuccess,
    (state, { allPelloDevices }) => ({
      ...state,
      allPelloDevices: allPelloDevices,
      showPelloDevicesLoading: false,
    })
  ),
  on(ContainerDeviceActions.resetPelloDevicesData, state => ({
    ...state,
    allPelloDevices: null,
    showPelloDevicesLoading: true,
  })),
  on(ContainerDeviceActions.getPelloDevicesFailure, state => ({
    ...state,
    showPelloDevicesLoading: false,
  })),
  on(ContainerDeviceActions.getSequoiaDevicesFailure, state => ({
    ...state,
    showSequoiaDevicesLoading: false,
  })),
  on(ContainerDeviceActions.createDemoRequestSuccess, state => ({
    ...state,
    demoRequestsuccess: true,
  })),
  on(ContainerDeviceActions.createDemoRequestFailure, state => ({
    ...state,
    demoRequestsuccess: false,
  })),
  on(ContainerDeviceActions.resetDemoRequest, state => ({
    ...state,
    demoRequestsuccess: null,
  }))
);
