import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss'],
})
export class CustomSnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      message: string;
      actions: {
        action: () => void;
        actionLabel: string;
        color?: string;
      }[];
    }
  ) {}

  onActionClick(action: {
    action: () => void;
    actionLabel: string;
    color?: string;
  }) {
    action.action();
    this.snackBarRef.dismiss();
  }
}
