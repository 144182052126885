import { Injectable } from '@angular/core';
import { TwilioVerificationResponse } from '@app/core/models/twilio.model';
import { TwilioService } from '@app/core/services/azure-functions/twilio.service';
import { TwilioActions } from '@app/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class TwilioEffects {
  constructor(
    private actions$: Actions,
    private TwilioService: TwilioService
  ) {}

  twilioVerify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TwilioActions.twilioVerify),
      switchMap(({ requestObj }) => {
        if (requestObj.channel === 'sms') {
          return this.TwilioService.twilioVerify(requestObj).pipe(
            map((response: TwilioVerificationResponse) =>
              TwilioActions.verifyPhoneNumberSuccess({ response })
            ),
            catchError(err =>
              of(TwilioActions.verifyPhoneNumberFailure({ errorMsg: err }))
            )
          );
        }

        return this.TwilioService.twilioVerify(requestObj).pipe(
          map((response: TwilioVerificationResponse) =>
            TwilioActions.verifyEmailSuccess({ response })
          ),
          catchError(err =>
            of(TwilioActions.verifyEmailFailure({ errorMsg: err }))
          )
        );
      })
    )
  );

  VerifyOTP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TwilioActions.verifyOTP),
      switchMap(({ requestObj }) =>
        this.TwilioService.verifyOTP(requestObj).pipe(
          map((response: TwilioVerificationResponse) => {
            return TwilioActions.verifyOTPSuccess({
              response: { ...response, channel: requestObj.channel },
            });
          }),
          catchError(err =>
            of(
              TwilioActions.verifyOTPFailure({
                errorMsg: err,
                channel: requestObj.channel,
              })
            )
          )
        )
      )
    )
  );
}
