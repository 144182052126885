<mat-form-field
  class="multi-select-autocomplete"
  appearance="standard"
  color="accent"
  style="min-width: 50px; width: 100%; padding-right: 0.2rem">
  <mat-label>{{ label }}</mat-label>
  <input
    type="text"
    aria-label="Select Options"
    matInput
    [matAutocomplete]="auto"
    [formControl]="formControl"
    style="color: #000" />
</mat-form-field>
<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayFn"
  [panelWidth]="optionsWidth">
  <mat-option
    *ngFor="let option of filteredOptions | async"
    [value]="option"
    (click)="optionClicked($event, option)"
    style="font-size: 0.9rem">
    <mat-checkbox
      [checked]="selectedOptions.includes(option)"
      (change)="toggleSelection(option)"
      (click)="$event.stopPropagation()">
      {{ option }}
    </mat-checkbox>
  </mat-option>
</mat-autocomplete>
