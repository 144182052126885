import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOptions',
})
export class FilterOptionsPipe implements PipeTransform {
  transform(options: string[], searchTerm: string): string[] {
    if (!searchTerm) return options;
    return options.filter(option =>
      option.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
}
