<div>{{ data.message }}</div>
<div style="display: flex; justify-content: end">
  <button
    mat-button
    (click)="onActionClick(action)"
    *ngFor="let action of data.actions"
    [key]="action.actionLabel"
    [color]="action.color ? action.color : 'accent'">
    {{ action.actionLabel }}
  </button>
</div>
