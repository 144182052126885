import {
  TwilioVerification,
  TwilioVerificationResponse,
} from '@app/core/models/twilio.model';
import { createAction, props } from '@ngrx/store';

export const twilioVerify = createAction(
  '[User Data] Twilio Verification',
  props<{ requestObj: TwilioVerification }>()
);

export const verifyPhoneNumberSuccess = createAction(
  '[User Data] Verify Phone Number Success',
  props<{ response: TwilioVerificationResponse }>()
);

export const verifyPhoneNumberFailure = createAction(
  '[User Data] Verify Phone Number Failure',
  props<{ errorMsg: string }>()
);

export const verifyEmailSuccess = createAction(
  '[User Data] Verify Email Success',
  props<{ response: TwilioVerificationResponse }>()
);

export const verifyEmailFailure = createAction(
  '[User Data] Verify Email Failure',
  props<{ errorMsg: string }>()
);

export const verifyOTP = createAction(
  '[User Data] Verify OTP',
  props<{ requestObj: TwilioVerification }>()
);

export const verifyOTPSuccess = createAction(
  '[User Data] Verify OTP Success',
  props<{ response: TwilioVerificationResponse }>()
);

export const verifyOTPFailure = createAction(
  '[User Data] Verify OTP Failure',
  props<{ errorMsg: string; channel: 'sms' | 'email' }>()
);
