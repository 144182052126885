import {
  InvoiceDetail,
  InvoiceFile,
} from '@app/core/api-azure-functions/dataverse-functions/model/invoice';
import { InvoiceResponse } from '@app/core/api/model/invoiceResponse';
import { InvoicesActions } from '@app/store/actions';
import { createReducer, on } from '@ngrx/store';

export const invoicesFeatureKey = 'invoices';

export interface InvoicesState {
  invoices: InvoiceResponse[] | null;
  invoiceDetails: InvoiceDetail | null;
  invoiceFile: InvoiceFile | null;
  invoiceFileToEmbed: string | null;
  tableState: any;
  invoiceFileLoading: boolean;
  loading: boolean;
}

const initialState: InvoicesState = {
  invoices: null,
  invoiceDetails: null,
  invoiceFile: null,
  invoiceFileToEmbed: null,
  tableState: {
    searchText: '',
    pageNumber: null,
  },
  invoiceFileLoading: true,
  loading: true,
};

export const invoicesReducer = createReducer(
  initialState,
  on(InvoicesActions.getInvoices, state => ({
    ...state,
    loading: true,
  })),
  on(InvoicesActions.getInvoicesSuccess, (state, { invoices }) => ({
    ...state,
    invoices,
    loading: false,
  })),
  on(
    InvoicesActions.setInvoicesListTableState,
    (state, { searchText, pageNumber }) => ({
      ...state,
      tableState: {
        searchText,
        pageNumber,
      },
    })
  ),
  on(InvoicesActions.getInvoiceDetailsSuccess, (state, { invoiceDetails }) => ({
    ...state,
    invoiceDetails,
  })),
  on(InvoicesActions.resetInvoiceDetails, state => ({
    ...state,
    invoiceDetails: null,
  })),
  on(InvoicesActions.getInvoiceFileSuccess, (state, { invoiceFile }) => ({
    ...state,
    invoiceFile,
    invoiceFileLoading: false,
  })),
  on(InvoicesActions.invoiceFileLoadingStart, state => ({
    ...state,
    invoiceFileLoading: true,
  })),
  on(
    InvoicesActions.getInvoiceFileToEmbedSuccess,
    (state, { invoiceFileToEmbed }) => ({
      ...state,
      invoiceFileToEmbed,
      invoiceFileLoading: false,
    })
  ),
  on(InvoicesActions.resetInvoiceFileToEmbed, state => ({
    ...state,
    invoiceFileToEmbed: null,
  }))
);
