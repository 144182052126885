import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AvatarModule } from 'ngx-avatar';

import { environment as env } from '@env/environment';

import { ErrorComponent } from '@app/core/components/error/error.component';

import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';
import { DataverseService } from '@app/core/services/azure-functions/dataverse.service';
import { RtsProductsService } from '@app/core/services/azure-functions/rts-products.service';
import { SendEmailService } from '@app/core/services/azure-functions/send-email.service';
import { SequoiaService } from '@app/core/services/azure-functions/sequoia.service';
import { SharepointDocumentsService } from '@app/core/services/azure-functions/sharepoint-documents.service';
import { ContentfulService } from '@app/core/services/contentful/contentful.service';
import { FileService } from '@app/core/services/file.service';
import { LoaderErrorInterceptorService } from '@app/core/services/loaders/loader-error-interceptor.service';
import { RouteHistoryService } from '@app/core/services/routing/route-history.service';

import { SharedModule } from '@app/shared/shared.module';
import { FooterMainComponent } from './components/footer-main/footer-main.component';
import { GlobalBillingFilterComponent } from './components/global-billing-filter/global-billing-filter.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';

import { BASE_PATH } from './api-azure-functions/dataverse-functions';
import { msalConfig, protectedResources } from './auth-config';
import { DataverseSiteService } from './services/azure-functions/dataverse-site.service';

const components = [
  FooterMainComponent,
  SideNavComponent,
  ErrorComponent,
  TopNavComponent,
  SpinnerComponent,
  GlobalBillingFilterComponent,
];

const services = [
  RouteHistoryService,
  FileService,
  SendEmailService,
  SequoiaService,
  ContentfulService,
  AzureBaseService,
  DataverseService,
  RtsProductsService,
  SharepointDocumentsService,
  MsalService,
  MsalBroadcastService,
  DataverseSiteService,
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    AvatarModule,
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: { scopes: protectedResources.tokenAuthApim.scopes },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          // All dataverse endpoints except /option need protected
          [
            `${protectedResources.tokenAuthApim.baseUrl}/func-customerportal-dataverse-${env.azureEnvironment}/address*`,
            protectedResources.tokenAuthApim.scopes,
          ],
          [
            `${protectedResources.tokenAuthApim.baseUrl}/func-customerportal-dataverse-${env.azureEnvironment}/account*`,
            protectedResources.tokenAuthApim.scopes,
          ],
          [
            `${protectedResources.tokenAuthApim.baseUrl}/func-customerportal-dataverse-${env.azureEnvironment}/case*`,
            protectedResources.tokenAuthApim.scopes,
          ],
          [
            `${protectedResources.tokenAuthApim.baseUrl}/func-customerportal-dataverse-${env.azureEnvironment}/equipment*`,
            protectedResources.tokenAuthApim.scopes,
          ],
          [
            `${protectedResources.tokenAuthApim.baseUrl}/func-customerportal-dataverse-${env.azureEnvironment}/schedules*`,
            protectedResources.tokenAuthApim.scopes,
          ],
          [
            `${protectedResources.tokenAuthApim.baseUrl}/func-customerportal-dataverse-${env.azureEnvironment}/sites*`,
            protectedResources.tokenAuthApim.scopes,
          ],
          [
            `${protectedResources.tokenAuthApim.baseUrl}/func-customerportal-dataverse-${env.azureEnvironment}/activesites*`,
            protectedResources.tokenAuthApim.scopes,
          ],
          [
            `${protectedResources.tokenAuthApim.baseUrl}/func-customerportal-dataverse-${env.azureEnvironment}/workorder*`,
            protectedResources.tokenAuthApim.scopes,
          ],
          [
            `${protectedResources.tokenAuthApim.baseUrl}/func-customerportal-pello-*`,
            protectedResources.tokenAuthApim.scopes,
          ],
          [
            `${protectedResources.tokenAuthApim.baseUrl}/func-customerportal-sendgrid-*`,
            protectedResources.tokenAuthApim.scopes,
          ],
          [
            `${protectedResources.tokenAuthApim.baseUrl}/func-customerportal-sequoia-*`,
            protectedResources.tokenAuthApim.scopes,
          ],
          [
            `${protectedResources.tokenAuthApim.baseUrl}/func-rts-b2c-user-management-${env.azureEnvironment}/user*`,
            protectedResources.tokenAuthApim.scopes,
          ],
          [
            `${protectedResources.tokenAuthApim.baseUrl}/portal-api-*`,
            protectedResources.tokenAuthApim.scopes,
          ],
          [
            `${protectedResources.tokenAuthApim.baseUrl}/func-portal-api-*`,
            protectedResources.tokenAuthApim.scopes,
          ],
        ]),
      }
    ),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderErrorInterceptorService,
      multi: true,
    },
    {
      provide: BASE_PATH,
      useValue:
        protectedResources.tokenAuthApim.baseUrl +
        '/func-customerportal-dataverse-' +
        env.azureEnvironment,
    },
    DatePipe,
    services,
    MsalGuard,
  ],
  declarations: [components],
  exports: [components],
  bootstrap: [MsalRedirectComponent],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    // prevent CoreModule from being loaded anywhere other than AppModule
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. Import Core modules in the AppModule only.'
      );
    }
  }
}
