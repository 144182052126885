import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/shared/modules/material.module';
import { WavesModule } from 'angular-bootstrap-md';
import { Angular2CsvModule } from 'angular2-csv';

// components
import { BarComponent } from '@app/shared/bar/bar.component';
import { CollapsibleSection } from '@app/shared/components/collapsible-section/collapsible-section.component';
import { TextInputComponent } from '@app/shared/components/form-components/text-input/text-input.component';
import { FormLoadingVisualComponent } from '@app/shared/components/form-loading-visual/form-loading-visual.component';
import { GoogleMapComponent } from '@app/shared/components/google-map/google-map.component';
import { GridItemLoadingVisualComponent } from '@app/shared/components/grid-item-loading-visual/grid-item-loading-visual.component';
import { LegalDocumentModalComponent } from '@app/shared/components/legal-document-modal/legal-document-modal.component';
import { LegalDocumentComponent } from '@app/shared/components/legal-document/legal-document.component';
import { NoDataComponent } from '@app/shared/components/no-data/no-data.component';
import { SearchInputComponent } from '@app/shared/components/search-input/search-input.component';
import { TableLoadingVisualComponent } from '@app/shared/components/table-loading-visual/table-loading-visual.component';
import { WorkorderDetailsModalComponent } from '@app/shared/components/workorder-details-modal/workorder-details-modal.component';
import { CustomSnackbarComponent } from '@app/custom-snackbar/custom-snackbar.component';
import { ChatbotDialogComponent } from './components/chatbot-dialog/chatbot-dialog.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DatePickerComponent } from './components/form-components/date-picker/date-picker.component';
import { DropdownSelectComponent } from './components/form-components/dropdown-select/dropdown-select.component';
import { SubmitInputComponent } from './components/form-components/submit-input/submit-input.component';
import { TextAreaInputComponent } from './components/form-components/text-area-input/text-area-input.component';
import { LandingLoginComponent } from './components/hero-left-wrappers/landing-login/landing-login';
import { MultipleEmailInputComponent } from './components/multiple-email-input/multiple-email-input';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { PublicCaseFormComponent } from './components/public-case-form/public-case-form.component';
import { SupportTicketSubmitModalComponent } from './components/support-ticket-submit-modal/support-ticket-submit-modal.component';
import { TopSubNavComponent } from './components/top-sub-nav/top-sub-nav.component';
import { FeedbackDialogComponent } from './components/feeedback-dialog/feedback-dialog.component';
import { DateRangePickerComponent } from './components/form-components/date-range-picker/date-range-picker.component';

// pipes
import { ConvertFrom24To12Pipe } from './pipes/convert-from-24-to-12.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { ServiceFrequencyPipe } from './pipes/service-frequency.pipe';
import { ServiceStatusPipe } from './pipes/service-status.pipe';
import {
  UserInitialsDisplayPipe,
  UsernameDisplayPipe,
} from './pipes/username-display.pipe';

// directives
import { BookADemoDialogComponent } from './components/book-ademo-dialog/book-ademo-dialog.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { FeatureDirective } from './directives/feature.directive';
import { MatTableResponsiveDirective } from './directives/mat-table-responsive.directive';
import { SabFeatureDirective } from './directives/sabfeature.directive';
import { FilterOptionsPipe } from './pipes/filter-options.pipe';
import { MultiSelectAutocompleteComponent } from './components/multi-select-autocomplete/multi-select-autocomplete.component';

const components = [
  TopSubNavComponent,
  NoDataComponent,
  TableLoadingVisualComponent,
  GridItemLoadingVisualComponent,
  FormLoadingVisualComponent,
  SearchInputComponent,
  TextInputComponent,
  BarComponent,
  SubmitInputComponent,
  DropdownSelectComponent,
  TextAreaInputComponent,
  GoogleMapComponent,
  DatePickerComponent,
  CollapsibleSection,
  LegalDocumentModalComponent,
  LegalDocumentComponent,
  FileUploadComponent,
  ProgressBarComponent,
  LandingLoginComponent,
  ErrorModalComponent,
  SupportTicketSubmitModalComponent,
  PublicCaseFormComponent,
  ChatbotDialogComponent,
  MultipleEmailInputComponent,
  WorkorderDetailsModalComponent,
  BookADemoDialogComponent,
  FeedbackDialogComponent,
  DateRangePickerComponent,
  CustomSnackbarComponent,
  MultiSelectAutocompleteComponent,
];

const pipes = [
  ConvertFrom24To12Pipe,
  ServiceFrequencyPipe,
  ServiceStatusPipe,
  PhonePipe,
  UsernameDisplayPipe,
  UserInitialsDisplayPipe,
  DateAgoPipe,
  FilterOptionsPipe,
];

const directives = [
  FeatureDirective,
  SabFeatureDirective,
  MatTableResponsiveDirective,
  DragAndDropDirective,
];

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RouterModule,
    WavesModule,
    MaterialModule,
  ],
  declarations: [...components, ...pipes, ...directives],
  exports: [
    ...components,
    ...pipes,
    ...directives,
    Angular2CsvModule,
    MaterialModule,
  ],
})
export class SharedModule {}
