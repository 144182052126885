import { AnalyticsActions } from '@app/store/actions';
import { createReducer, on } from '@ngrx/store';
import {
  PowerBiReportDetails,
  RegionSite,
  Report,
  ReportRegion,
} from '@app/core/models/analytics.model';

export const analyticsFeatureKey = 'analytics';

export interface AnalyticsState {
  reportList: Report[];
  token: string | null;
  demoRequestsuccess: boolean;
  showReportsListLoading: boolean;
  reportRegionList: ReportRegion[];
  regionSiteList: RegionSite[];
  powerBiReportDetails: PowerBiReportDetails;
}

const initialState: AnalyticsState = {
  reportList: [],
  token: null,
  demoRequestsuccess: null,
  showReportsListLoading: false,
  reportRegionList: [],
  regionSiteList: [],
  powerBiReportDetails: null,
};

export const analyticsReducer = createReducer(
  initialState,
  on(AnalyticsActions.showReportsListLoading, state => ({
    ...state,
    showReportsListLoading: true,
  })),
  on(AnalyticsActions.getReportListSuccess, (state, { reportList }) => ({
    ...state,
    reportList: reportList.map(report => ({
      ...report,
      selected: false,
    })),
    showReportsListLoading: false,
  })),
  on(AnalyticsActions.getReportListFailure, state => ({
    ...state,
    showReportsListLoading: false,
  })),
  on(AnalyticsActions.getReportTokenSuccess, (state, { reportToken }) => {
    return {
      ...state,
      token: reportToken,
    };
  }),
  on(AnalyticsActions.resetReportToken, state => ({
    ...state,
    token: null,
  })),
  on(AnalyticsActions.createAnalyticDemoRequestSuccess, state => ({
    ...state,
    demoRequestsuccess: true,
  })),
  on(AnalyticsActions.createAnalyticDemoRequestFailure, state => ({
    ...state,
    demoRequestsuccess: false,
  })),
  on(AnalyticsActions.resetAnalyticDemoRequest, state => ({
    ...state,
    demoRequestsuccess: null,
  })),
  on(
    AnalyticsActions.getReportRegionListSuccess,
    (state, { reportRegionList }) => ({
      ...state,
      reportRegionList: reportRegionList.map(reportRegion => ({
        ...reportRegion,
        selected: false,
      })),
    })
  ),
  on(AnalyticsActions.getReportRegionListFailure, state => ({
    ...state,
  })),

  on(
    AnalyticsActions.getRegionSiteListSuccess,
    (state, { regionSiteList }) => ({
      ...state,
      regionSiteList: regionSiteList.map(regionSite => ({
        ...regionSite,
        selected: false,
      })),
    })
  ),
  on(AnalyticsActions.getRegionSiteListFailure, state => ({
    ...state,
  })),
  on(
    AnalyticsActions.getPowerBiReportDetailsSuccess,
    (state, { powerBiReportDetails }) => {
      return {
        ...state,
        powerBiReportDetails: powerBiReportDetails,
      };
    }
  ),
  on(AnalyticsActions.resetPowerBiReportDetails, state => ({
    ...state,
    powerBiReportDetails: null,
  }))
);
