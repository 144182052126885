import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthSelectors, RouterSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-muni-support',
  templateUrl: './muni-support.component.html',
  styleUrls: ['./muni-support.component.scss'],
})
export class MuniSupportComponent implements OnInit, OnDestroy {
  showBackButton: boolean;
  hideSupportButtons: boolean;
  subNavTitle = '';
  backButtonUrl: string;
  heroImageCol: number;
  styleClass: string;
  serviceSubscription: Subscription;
  selectedCase: any;
  backButtonQueryParams = undefined;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {
    this.serviceSubscription = new Subscription();
  }

  ngOnInit() {
    this.getRouterState();

    const subscription = this.store
      .select(AuthSelectors.getUser)
      .subscribe(b2cAccountInfo => {
        if (b2cAccountInfo) {
          this.router.navigate(['support-signed-in']);
        }
      });

    this.serviceSubscription.add(subscription);
  }

  ngOnDestroy() {
    this.serviceSubscription.unsubscribe();
  }

  getRouterState() {
    this.serviceSubscription.add(
      this.store
        .select(RouterSelectors.getRouterState)
        .subscribe((state: any) => {
          const data = state?.data;

          this.showBackButton = data?.showBackButton
            ? state.queryParams.hasBackButton
            : false;
          this.hideSupportButtons = data?.hideSupportButtons;
          this.subNavTitle = data?.title;
          this.backButtonUrl = data?.backButtonUrl;
          this.heroImageCol = data?.heroImageCol ? data?.heroImageCol : null;
          this.styleClass = `px-0 full-width ${data?.styleClass}`;
          this.selectedCase = state.params.caseTypeId;
          this.backButtonQueryParams = state.queryParams.hasBackButton
            ? { hasBackButton: true }
            : undefined;
          if (!this.selectedCase) {
            this.selectedCase = { id: state.params.caseTypeId };
          }
        })
    );
  }
}
