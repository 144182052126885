<ng-container *ngIf="(routerState$ | async)?.data as routerStateSnapshot">
  <app-top-sub-nav
    *ngIf="!routerStateSnapshot?.displayBreadCrumb"
    [subNavItems]="[]"
    [title]="routerStateSnapshot.title">
  </app-top-sub-nav>

  <div class="pello-id" *ngIf="billingAccounts.length > 0">
    <div *ngIf="!hideSupportButtons" class="col-14 col-md-12 d-flex">
      <ng-container>
        <mat-form-field appearance="outline">
          <mat-label>Pollo Id</mat-label>
          <mat-select
            placeholder="Select Pick Pollo Id"
            [(value)]="selectedPelloId"
            (selectionChange)="onSelectionChange($event)">
            <mat-option
              *ngFor="let billingAccount of billingAccounts"
              [value]="billingAccount.pelloId"
              [disabled]="!billingAccount.selected">
              {{ billingAccount.name }} - {{ billingAccount.pelloId }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
  <ng-container
    *ngIf="
      {
        showSequoiaDevicesLoading: showSequoiaDevicesLoading$ | async,
        showPelloDevicesLoading: showPelloDevicesLoading$ | async,
        pelloDevices: pelloDeviceData$ | async,
        sequoiaDevices: sequoiaDeviceData$ | async
      } as sensorDevices;
      else loading
    ">
    <ng-container
      *ngIf="
        sensorDevices.showSequoiaDevicesLoading ||
          sensorDevices.showPelloDevicesLoading;
        else loaded
      ">
      <div class="container-fluid sub-nav">
        <div class="row loading">
          <app-grid-item-loading-visual></app-grid-item-loading-visual>
          <app-grid-item-loading-visual></app-grid-item-loading-visual>
          <app-grid-item-loading-visual></app-grid-item-loading-visual>
          <app-grid-item-loading-visual></app-grid-item-loading-visual>
          <app-grid-item-loading-visual></app-grid-item-loading-visual>
          <app-grid-item-loading-visual></app-grid-item-loading-visual>
          <app-grid-item-loading-visual></app-grid-item-loading-visual>
          <app-grid-item-loading-visual></app-grid-item-loading-visual>
        </div>
      </div>
    </ng-container>
    <ng-template #loaded>
      <ng-container
        *ngIf="
          sensorDevices.pelloDevices.length ||
            sensorDevices.sequoiaDevices.length;
          else no_data
        ">
        <div class="container-fluid sub-nav" [dynamicHeight]="true">
          <nav
            mat-tab-nav-bar
            color="accent"
            *ngIf="!routerStateSnapshot?.displayBreadCrumb">
            <ng-container
              *ngIf="{
                showPelloDevicesLoading: showPelloDevicesLoading$ | async,
                pelloDevices: pelloDeviceData$ | async
              } as continerDevices">
              <ng-container
                *ngIf="
                  !continerDevices.showPelloDevicesLoading;
                  else tableLoadingVisual
                ">
                <ng-container *ngIf="continerDevices.pelloDevices.length">
                  <a
                    mat-tab-link
                    routerLink="/sensors/container-devices"
                    routerLinkActive
                    #rla="routerLinkActive"
                    [active]="rla.isActive">
                    Container Devices
                  </a>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container
              *ngIf="{
                showSequoiaDevicesLoading: showSequoiaDevicesLoading$ | async,
                sequoiaDevices: sequoiaDeviceData$ | async
              } as compactorMonitors">
              <ng-container
                *ngIf="
                  !compactorMonitors.showSequoiaDevicesLoading;
                  else tableLoadingVisual
                ">
                <ng-container *ngIf="compactorMonitors.sequoiaDevices.length">
                  <a
                    mat-tab-link
                    routerLink="/sensors/compactor-monitors"
                    routerLinkActive
                    #rla="routerLinkActive"
                    [active]="rla.isActive">
                    Compactor Monitors
                  </a>
                </ng-container>
              </ng-container>
            </ng-container>
          </nav>
          <router-outlet></router-outlet>
        </div>
      </ng-container>
      <ng-template #no_data>
        <div
          class="container-fluid sub-nav no-data-container"
          *ngIf="!loadingDevices">
          <div class="book-demo-container row">
            <div class="book-demo-content col-12 col-md-6">
              <h2>Unlock the Power of Pello</h2>
              <h5>Smart Waste Sensors</h5>
              <ul>
                <li>Real-time visibility into your bins</li>
                <li>Data-driven insights</li>
                <li>Cost savings</li>
                <li>Reduce waste and contamination</li>
              </ul>
              <button
                class="col-12 col-sm-auto"
                mat-raised-button
                color="accent"
                (click)="openBookADemoDialog()">
                Book a Demo
              </button>
            </div>
            <div
              class="book-demo-ad-image col-12 col-md-6 d-none d-sm-flex"></div>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </ng-container>
</ng-container>
