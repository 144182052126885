export const REGEX_PATTERN = Object.freeze({
  EMAIL: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$',
  ZIPCODE: '^[0-9]{5}(?:-[0-9]{4})?$',
  CA_POSTALCODE: '^[A-Za-z][0-9][A-Za-z][ -]?[0-9][A-Za-z][0-9]$',
  // prettier-ignore
  // eslint-disable-next-line no-useless-escape
  PHONENUMBER: '^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$',
});

export const SSRS_REPORT_BASE_URL =
  'https://reports.rts.com/ReportServer/Pages/ReportViewer.aspx?%2fReports%2f';

export const POWER_BI_REPORT_BASE_URL =
  'https://app.powerbi.com/reportEmbed?autoAuth=true&filter=Portal_Users%2F&ctid=36ff142e-a1a0-4517-9258-e9179df35506';

export const CP_PERMISSIONS = Object.freeze({
  DASHBOARD_READ: 'CP:Dashboard:OrgRead',
  DOCUMENTS_READ: 'CP:Documents:OrgRead',

  SITES_SERVICESCHEDULE_READ: 'CP:Sites:ServiceSchedule:OrgRead',
  SITES_SITEINFO_READ: 'CP:Sites:SiteInfo:OrgRead',
  SITES_INVOICE_READ: 'CP:Site-Invoice:OrgRead',
  SITES_SCHEDULE_READ: 'CP:Sites:Schedule:OrgRead',

  SUPPORTREQUEST_READ: 'CP:SupportRequest:OrgRead',
  SUPPORTREQUEST_WRITE: 'CP:SupportRequest:OrgWrite',

  SERVICEREQUEST_READ: 'CP:ServiceRequest:OrgRead',
  SERVICEREQUEST_WRITE: 'CP:ServiceRequest:OrgWrite',
  SERVICEMGMT_SITEDETAILS_READ: 'CP:ServiceMgmt:SiteDetails:OrgRead',
  SERVICEMGMT_SCHEDULE_READ: 'CP:ServiceMgmt:Schedule:OrgRead',

  SENSORS_SEQUOIADATA_READ: 'CP:Sensors:SequoiaData:OrgRead',
  SENSORS_SEQUOIAIMAGES_READ: 'CP:Sensors:SequoiaImages:OrgRead',
  SENSORS_PELLODATA_READ: 'CP:Sensors:PelloData:OrgRead',
  SENSORS_PELLOIMAGES_READ: 'CP:Sensors:PelloImages:OrgRead',
  ONETIME_READ: 'CP:One-Time:OrgRead',

  ANALYTICS_REPORTS_READ: 'CP:Analytics:Reports:OrgRead',
  ANALYTICS_SUSTAINABILITY_READ: 'CP:Analytics:Sustainability:OrgRead',
  ANALYTICS_SENSORS_READ: 'CP:Analytics:Sensors:OrgRead',
  // REPORTS_DIVERSIONREPORTS_READ: 'CP:Reports:DiversionReports:OrgRead',
  // REPORTS_SUSTAINABILITY_READ: 'CP:Reports:Sustainability:OrgRead',
  // REPORTS_CYCLECOLLECTION_READ: 'CP:Reports:CycleCollection:OrgRead',
  // REPORTS_GAMIFICATION_READ: 'CP:Reports:Gamification:OrgRead',

  SUPPORTREQUEST_SUSTAINABILITY_WRITE:
    'CP:SupportRequest:Sustainability:OrgWrite',
  SUPPORTREQUEST_REPORTING_WRITE: 'CP:SupportRequest:Reporting:OrgWrite',

  INVOICES_READ: 'CP:Invoices:OrgRead',
  // INVOICES_ORGPAY: 'CP:Invoices:OrgPay',
  // INVOICES_ORGDISPUTE: 'CP:Invoices:OrgDispute',
  HAULERINVOICES_READ: 'CP:HaulerInvoices:OrgRead',
  VENDORINVOICE_READ: 'CP:VendorInvoices:OrgRead',

  HAULERCONTRACT_READ: 'CP:HaulerMgmt:Contracts:OrgRead',

  SERVICECHANGE_WRITE: 'CP:ServiceChange:OrgWrite',
  CANCELSITE_WRITE: 'CP:CancelSite:OrgWrite',
});

export const CASE_ORIGIN_CODE = Object.freeze({
  CUSTOMER_PORTAL: 118100000,
  PUBLIC_CASE_FORM: 957510001,
  CORRIGO_PRO: 4,
  ELYTUS: 5,
});

// CE:  casetypecode
export const SUPPORT_TYPES = Object.freeze({
  SERVICE_REQUEST: '3',
  FINANCE: '118100000',
  SERVICE_CHANGE: '100000001',
  DAMAGED_EQUIPMENT: '118100002',
  MISSED_SERVICE: '118100006',
  INCIDENTS_ACCIDENTS: '118100007',
  SUSTAINIBILITY: '118100014',
  REPORTING: '118100016',
  OTHER: '100000008',
  SERVICE_SCHEDULE_INQUIRY: '118100010',
});

// CE: new_rtssubcasetype
export const CUSTOMER_SUB_CASE_TYPES = Object.freeze({
  INVOICE_REQUEST: '100000036',
  INVOICE_DISCREPANCY: '100000037',
  MISSING_PO: '100000038',
});

// CE: new_rtssubcasetype
export const HAULER_SUB_CASE_TYPES = Object.freeze({
  STOP_SERVICE: '100000039',
  PAYMENT_ISSUE: '100000040',
});

// CE: new_rtssubcasetype
export const REPORTING_SUB_CASE_TYPES = Object.freeze({
  NEW_REPORT_REQUEST: '118100013',
  MISSING_REPORT_REQUEST: '118100014',
  QUESTIONS_ON_DATA_REPORT: '118100015',
});

export const SERVICE_CHANGE_TYPES = Object.freeze({
  RECURRING: 'Recurring', // rts_workorderf0 - 118100002
  SCHEDULE_CHANGE: '118100006', // rts_changetype
  CANCELLATION: '118100017', // casetypecode

  CANCEL_SITE: '118100044', // new_rtssubcasetype
  CANCEL_SERVICE: '118100045', // new_rtssubcasetype
});

export const RECURRING_SITE_TYPE = Object.freeze({
  NEW_SITE: '118100010', // rts_changetype
  EXISTING_SITE: '118100011', // rts_changetype
});

export const ALLOWED_CASE_TYPES = [
  SUPPORT_TYPES.MISSED_SERVICE,
  SUPPORT_TYPES.DAMAGED_EQUIPMENT,
  SUPPORT_TYPES.INCIDENTS_ACCIDENTS,
  SUPPORT_TYPES.FINANCE,
  SUPPORT_TYPES.SERVICE_REQUEST,
  SUPPORT_TYPES.SERVICE_CHANGE,
  SUPPORT_TYPES.SUSTAINIBILITY,
  SUPPORT_TYPES.REPORTING,
  SUPPORT_TYPES.SERVICE_SCHEDULE_INQUIRY,
  SUPPORT_TYPES.OTHER,
];

export const STATUS_CODE_MAP = {
  118100002: 'New',
  1: 'In Progress',
  2: 'In Progress', // On Hold - show In Progress
  3: 'In Progress', // Waiting for Details - show In Progress
  4: 'In Progress', // Researching - show In Progress
  118100000: 'In Progress', // Address Customer - show In Progress
  118100001: 'Needs Customer Input', // Pending Customer Input
  5: 'Resolved', // Problem Solved
  1000: 'Resolved', // Information Provided
  6: 'Resolved', // Canceled
  2000: 'Resolved', // Merged
};

export const ADVANCE_REPORT_CATEGORIES = [
  {
    name: 'Waste Stream Analysis',
    routerLink: '/analytics/advanceReport/d6f6c8c9-1195-4b5f-a19a-c1c709ccb3b8',
    featureName: 'Waste Stream Analysis',
    id: 'd6f6c8c9-1195-4b5f-a19a-c1c709ccb3b8',
  },
  {
    name: 'Diversion Analysis',
    routerLink: '/analytics/advanceReport/8111d7a7-15d2-4e4a-93a7-3525a8e67aa7',
    featureName: 'Diversion Analysis',
    id: '8111d7a7-15d2-4e4a-93a7-3525a8e67aa7',
  },
  {
    name: 'Roll Off Analysis',
    routerLink: '/analytics/advanceReport/8c1bbc60-14f4-470a-a68f-1077ec7c977b',
    featureName: 'Roll Off Analysis',
    id: '8c1bbc60-14f4-470a-a68f-1077ec7c977b',
  },
  {
    name: 'Service Details',
    routerLink: '/analytics/advanceReport/92bd1ca8-4c87-4c1c-b2a1-3a0ec971c228',
    featureName: 'Service Details',
    id: '92bd1ca8-4c87-4c1c-b2a1-3a0ec971c228',
  },
];
