<div class="container-fluid px-0 main-nav">
  <mat-toolbar>
    <mat-toolbar-row class="main-nav row-toolbar">
      <span class="d-none d-md-flex col-6 company-name pl-0">
        <ng-container *ngIf="billingAccounts$">
          <app-global-billing-filter> </app-global-billing-filter>
        </ng-container>
      </span>
      <span class="d-flex d-md-none col-3 nav-logo">
        <a [routerLink]="'/dashboard'">
          <img
            src="assets/images/rts-logo-white.png"
            alt="Recycle Track Systems" />
        </a>
      </span>

      <div class="d-none d-md-flex col justify-content-end">
        <div
          class="dropdown-md notifications-dropdown"
          *ngIf="whatsNew$ | async as whatsNew">
          <button
            mat-icon-button
            class="notification-toggle-wrapper"
            [matMenuTriggerFor]="notificationsMenu">
            <img src="assets/images/notification.svg" alt="Notifications" />
            <div
              *ngIf="(hasSeenWhatsNew$ | async) === false"
              class="notification-circle">
              <div class="notification-circle-inner"></div>
            </div>
          </button>
          <mat-menu #notificationsMenu="matMenu" class="dropdown-menu-md">
            <a
              mat-menu-item
              (click)="redirectToWhatsNew()"
              class="dropdown-nav-item-md"
              >What's New</a
            >
          </mat-menu>
        </div>
      </div>

      <div class="d-flex col-9 col-md-auto justify-content-end" id="user-name">
        <ng-container *ngIf="user$ | async as user">
          <a
            [matMenuTriggerFor]="hamburgerMenu"
            class="username d-none d-md-flex">
            <ngx-avatar
              size="32"
              [value]="user.name | userInitialsDisplayPipe"
              class="md-avatar"></ngx-avatar>
            {{ user.name | usernameDisplayPipe }}
          </a>
          <button
            class="hamburger-menu col-auto d-block d-md-none"
            [matMenuTriggerFor]="hamburgerMenu">
            <span></span>
            <span></span>
            <span></span>
            <div
              *ngIf="(hasSeenWhatsNew$ | async) === false"
              class="mobile-notification-circle"></div>
          </button>
          <mat-menu #hamburgerMenu="matMenu" class="hamburgerMenu">
            <ng-container *ngFor="let page of pages">
              <ng-container *appFeature="page.featureName">
                <button
                  mat-menu-item
                  [routerLink]="
                    page.pageSubLink && page.pageSubLink.length > 0
                      ? null
                      : page.routerLink
                  "
                  (click)="showPageSubLink($event, page.name)"
                  style="display: flex; align-items: center"
                  class="d-md-none {{
                    (routerState$ | async)?.data.parent === page.name
                      ? 'page-active'
                      : (routerState$ | async)?.data.title === page.name
                        ? 'page-active'
                        : ''
                  }}">
                  <div
                    class="nav-icon"
                    [style.mask]="
                      'url(' + page.icon + ') no-repeat center / contain'
                    "
                    [style.-webkit-mask]="
                      'url(' + page.icon + ') no-repeat center / contain'
                    "></div>
                  <span id="spanMenu">
                    {{ page.name }}
                  </span>
                  <mat-icon
                    fontIcon="{{
                      activeMenu === page.name ? 'expand_less' : 'expand_more'
                    }}"
                    *ngIf="page.pageSubLink && page.pageSubLink.length > 0"
                    style="top: 0.25rem; position: relative; font-size: 1.2rem">
                  </mat-icon>
                </button>
                <!-- Sub-Category -->
                <div *ngIf="activeMenu === page.name">
                  <ul
                    *ngIf="page.pageSubLink && page.pageSubLink.length > 0"
                    class="nav-sub-items"
                    style="
                      list-style: none;
                      font-size: 0.85rem;
                      margin-bottom: 0;
                    ">
                    <li
                      *ngFor="let subPageItem of page.pageSubLink"
                      class="nav-sub-list-item"
                      style="line-height: 2rem">
                      <a (click)="showSubItem($event, subPageItem.subItemName)">
                        <span
                          id="{{
                            subPageItem.subItemName === activeSubItemsList
                              ? 'spanMenu'
                              : ''
                          }}"
                          >{{ subPageItem.subItemName }}
                        </span>
                        <mat-icon
                          fontIcon="{{
                            activeSubItemsList === subPageItem.subItemName
                              ? 'expand_less'
                              : 'expand_more'
                          }}"
                          *ngIf="
                            subPageItem.subItem &&
                            subPageItem.subItem.length > 0
                          "
                          style="
                            height: 1.125rem;
                            top: 0.25rem;
                            position: relative;
                            font-size: 1.2rem;
                          ">
                        </mat-icon>
                      </a>
                      <!-- Sab Category Item -->
                      <div
                        *ngIf="activeSubItemsList === subPageItem.subItemName">
                        <ul
                          *ngIf="
                            subPageItem.subItem &&
                            subPageItem.subItem.length > 0
                          "
                          style="
                            list-style: none;
                            padding-left: 0.5rem;
                            font-size: 0.95rem;
                          ">
                          <ng-container
                            *ngFor="let child of subPageItem.subItem">
                            <li
                              *appSabFeature="{
                                featureName: child.featureName,
                                featureType: subPageItem.featureName,
                                id: child.id
                              }"
                              class="nav-sub-category-item"
                              style="line-height: 1.5rem">
                              <a
                                [routerLink]="child.routerLink"
                                (click)="activeSubMenu(child.name)"
                                style="color: inherit">
                                <span
                                  id="{{
                                    child.name === activeSubItem
                                      ? 'spanMenu'
                                      : ''
                                  }}"
                                  style="
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    width: 90%;
                                    display: inline-block;
                                  ">
                                  {{ child.name }}
                                </span>
                              </a>
                            </li>
                          </ng-container>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </ng-container>
            </ng-container>
            <a mat-menu-item class="d-md-none" (click)="redirectToWhatsNew()">
              What's New
              <div
                *ngIf="(hasSeenWhatsNew$ | async) === false"
                class="red-notification-circle"></div>
            </a>
            <a mat-menu-item (click)="logout($event)">Log Out</a>
          </mat-menu>
        </ng-container>
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="d-flex d-md-none mobile-company-name-container p-0">
      <div class="col-12 company-name">
        <ng-container *ngIf="billingAccounts$ | async as billingAccounts">
          <app-global-billing-filter> </app-global-billing-filter>
        </ng-container>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
