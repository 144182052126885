import { SurveyData } from '@app/core/models/survey-settings.model';
import { createAction, props } from '@ngrx/store';

export const getSurveySettings = createAction('[Survey] Load Survey Settings');
export const getSurveySettingsSuccess = createAction(
  '[Survey] Load Survey Settings Success',
  props<{ survey: SurveyData[] }>()
);
export const getSurveySettingsFailure = createAction(
  '[Survey] Load Survey Settings Failure',
  props<{ errorMsg: string }>()
);
