import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GoogleMapLocation } from '@app/core/models/google-map-location.model';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import MarkerClusterer from '@google/markerclustererplus';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit {
  @ViewChild('googleMap', { static: true }) googleMapRef: ElementRef;

  @Input() locations$: Observable<GoogleMapLocation[]>;
  @Input() mapHeight: string;
  @Input() minHeight: string;
  infoWindow = new google.maps.InfoWindow();
  locationsArr: GoogleMapLocation[] = [];
  markerCluster: any;

  constructor() {}

  ngOnInit(): void {
    this.locations$
      .pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        )
      )
      .subscribe(locations => {
        this.locationsArr = locations.filter(location =>
          this.isValidLocation(location)
        );
        this.initMap();
      });
  }

  private isValidLocation(location: GoogleMapLocation): boolean {
    return (
      location.address1 !== null &&
      location.city !== null &&
      location.state !== null &&
      location.zip !== null
    );
  }

  private async geocodeAddress(
    location: GoogleMapLocation
  ): Promise<google.maps.LatLng | null> {
    const geocoder = new google.maps.Geocoder();

    return new Promise<google.maps.LatLng>(resolve => {
      geocoder.geocode(
        {
          address: `${
            location.name.length ? location.name.split('-')[0] + ',' : ''
          }${location.address1}${
            location.address2.length ? ',' + location.address2 : ''
          },${location.city},${location.state},${location.zip}`,
        },
        (
          results: google.maps.GeocoderResult[],
          status: google.maps.GeocoderStatus
        ) => {
          if (
            status === 'OK' &&
            results[0] &&
            results[0].geometry &&
            results[0].geometry.location
          ) {
            resolve(results[0].geometry.location);
          } else {
            console.error('Geocode was not successful for location:', location);
            resolve(null);
          }
        }
      );
    });
  }

  private async initMap(): Promise<void> {
    const mapOptions: google.maps.MapOptions = {
      mapTypeId: 'roadmap',
      disableDoubleClickZoom: true,
    };

    const map: google.maps.Map = new google.maps.Map(
      this.googleMapRef.nativeElement,
      mapOptions
    );

    const markers: google.maps.Marker[] = [];
    const latLngBounds = new google.maps.LatLngBounds();

    for (const location of this.locationsArr) {
      let position: google.maps.LatLng | null = null;

      if (location.latitude && location.longitude) {
        position = new google.maps.LatLng(
          location.latitude,
          location.longitude
        );
      } else {
        // eslint-disable-next-line no-await-in-loop
        position = await this.geocodeAddress(location);
      }

      if (position) {
        latLngBounds.extend(position);
        const marker = new google.maps.Marker({
          position: position,
          draggable: false,
          title: location.name,
          icon: '../../assets/images/map_pin.svg',
        });

        markers.push(marker);
        const contentString = this.generateInfoWindowContent(location);

        this.attachInfoWindow(marker, map, contentString);
      }
    }

    this.markerCluster = new MarkerClusterer(map, markers, {
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    });

    map.setCenter(latLngBounds.getCenter());
    map.fitBounds(latLngBounds);

    if (this.locationsArr.length === 1) {
      google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
        map.setZoom(14);
      });
    }
  }

  private generateInfoWindowContent(location: GoogleMapLocation): string {
    return `
      <div class="rts-info-window">
        <h5>${location.name}</h5>
        <p>${location.address1}, ${
          location.address2 ? location.address2 + ',' : ''
        } ${location.city}, ${location.state}, ${location.zip}</p>
        <a href="/organization/site/${location.customerId}/${
          location.accountId
        }/general">View site</a>
      </div>`;
  }

  private attachInfoWindow(
    marker: google.maps.Marker,
    map: google.maps.Map,
    contentString: string
  ): void {
    google.maps.event.addListener(marker, 'click', () => {
      this.infoWindow.setContent(contentString);
      this.infoWindow.open(map, marker);
    });

    google.maps.event.addListener(map, 'click', () => {
      this.infoWindow.close();
    });
  }
}
