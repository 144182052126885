import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FileService } from '@app/core/services/file.service';
import { SupportPublicService } from '@app/core/api/api/support-public.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { MuniSupportService } from '@app/core/api/api/muni-support.service';
import { TwilioActions, TwilioSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';

@Component({
  selector: 'app-public-case-form',
  templateUrl: './public-case-form.component.html',
  styleUrls: ['./public-case-form.component.scss'],
})
export class PublicCaseFormComponent implements OnInit, OnDestroy {
  @Input() caseTypeId = null;
  @Input() parentForm: FormGroup;
  @Input() errorStateMatcher: ErrorStateMatcher;
  @Input() descriptionPlaceholderText: string =
    'Please describe your request in detail. Where is the item located? What is the issue?';
  @Input() country: 'us' | 'canada';
  @Input() supportType?: 'public-support' | 'muni-support' = 'public-support';

  @Output() fileEncoding: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() base64String: EventEmitter<string> = new EventEmitter<string>();

  subscriptions: Subscription = new Subscription();
  todayDate: string = new Date().toISOString();
  statesDropdownOptions: { value: string; text: string }[];
  wasteStreamDropdownOptions: { value: string; text: string }[];
  equipmentTypeDropdownOptions: {
    value: string;
    text: string;
    options: string[];
  }[];
  equipmentSizeDropdownOptions: { value: string; text: string }[] = [];

  isPhoneValidationSent$ = this.store.select(
    TwilioSelectors.selectIsPhoneValidationSent
  );

  isEmailValidationSent$ = this.store.select(
    TwilioSelectors.selectIsEmailValidationSent
  );

  isPhoneValidated$: Observable<boolean> = this.store.select(
    TwilioSelectors.selectIsPhoneValidated
  );

  isEmailValidated$: Observable<boolean> = this.store.select(
    TwilioSelectors.selectIsEmailValidated
  );

  twilioError$ = this.store.select(TwilioSelectors.selectError);

  constructor(
    private store: Store<AppState>,
    private supportPublicService: SupportPublicService,
    private muniSupportService: MuniSupportService,
    public modal: MatDialog,
    private fileService: FileService
  ) {}

  ngOnInit() {
    if (this.supportType === 'muni-support') {
      this.wasteStreamDropdownOptions =
        this.muniSupportService.getWasteStreamOptions();
      this.equipmentTypeDropdownOptions =
        this.muniSupportService.getEquipmentTypeOptions();
    } else {
      this.wasteStreamDropdownOptions =
        this.supportPublicService.getWasteStreamOptions();
      this.equipmentTypeDropdownOptions =
        this.supportPublicService.getEquipmentTypeOptions();
    }

    this.statesDropdownOptions = this.supportPublicService.getStates(
      this.country
    );

    if (this.caseTypeId === '3' || this.caseTypeId === '118100006') {
      this.parentForm.get('pickupDate').setValidators([Validators.required]);
    }

    this.parentForm.controls.equipmentType.valueChanges.subscribe(
      selectedValue => {
        this.parentForm.controls.equipmentSize.reset();
        if (!selectedValue) {
          this.parentForm.controls.equipmentSize.disable();
          this.equipmentSizeDropdownOptions = [];
        } else {
          this.parentForm.controls.equipmentSize.enable();
          const founSelectedEquipmentSizeOption =
            this.equipmentTypeDropdownOptions.find(
              equipmentTypeDropdownOption =>
                equipmentTypeDropdownOption.value === selectedValue
            );

          if (founSelectedEquipmentSizeOption) {
            this.equipmentSizeDropdownOptions =
              founSelectedEquipmentSizeOption.options.map(str => ({
                text: str,
                value: str,
              }));

            // If there is only one size option then select the size directly
            if (this.equipmentSizeDropdownOptions.length === 1) {
              this.parentForm.controls.equipmentSize.setValue(
                this.equipmentSizeDropdownOptions[0].value
              );
            }
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setFile(file) {
    if (file) {
      // incase the string takes a long time to generate, wait until generated
      // disable submit button while base64 is generating
      this.fileEncoding.emit(true);
      // set file to the form control for form validation
      this.parentForm.controls.fileUpload.setValue(file);
      // generate and save base64 string at the component level
      this.fileService.getBase64(file).then((result: string) => {
        // only grab the base64 string
        const base64String = result.split(';base64,')[1];

        // emit the base64 string to the parent component
        this.base64String.emit(base64String);
        // enable submit after created
        this.fileEncoding.emit(false);
      });
    } else {
      // reset to null and mark field as invalid by resetting the required validator
      this.parentForm.controls.fileUpload.setValue(null);
      this.parentForm.controls.fileUpload.setValidators([Validators.required]);
      this.parentForm.controls.fileUpload.updateValueAndValidity();
      // reset component level base64 string
      this.base64String.emit(null);
    }
  }

  validateTwilio(event: { control: FormControl; name: 'email' | 'phone' }) {
    if (event.name === 'email') {
      const emailField = this.parentForm.get('email');

      if (emailField.valid) {
        this.store.dispatch(
          TwilioActions.twilioVerify({
            requestObj: {
              channel: 'email',
              PhoneNumberOrEmail: emailField.value,
            },
          })
        );
      }
    } else if (event.name === 'phone') {
      const phoneNumberField = this.parentForm.get('phone');

      if (phoneNumberField.valid) {
        this.store.dispatch(
          TwilioActions.twilioVerify({
            requestObj: {
              channel: 'sms',
              PhoneNumberOrEmail: `+1${phoneNumberField.value}`,
            },
          })
        );
      }
    }
  }

  verifyOTP(event: { control: FormControl; name: 'phoneOtp' | 'emailOtp' }) {
    if (event.name === 'phoneOtp') {
      const phoneNumberField = this.parentForm.get('phone');
      const otpField = this.parentForm.get('phoneOtp');

      if (phoneNumberField.valid && otpField.valid) {
        this.store.dispatch(
          TwilioActions.verifyOTP({
            requestObj: {
              channel: 'sms',
              PhoneNumberOrEmail: `+1${phoneNumberField.value}`,
              code: otpField.value,
            },
          })
        );
      }
    } else if (event.name === 'emailOtp') {
      const emailField = this.parentForm.get('email');
      const otpField = this.parentForm.get('emailOtp');

      if (emailField.valid && otpField.valid) {
        this.store.dispatch(
          TwilioActions.verifyOTP({
            requestObj: {
              channel: 'email',
              PhoneNumberOrEmail: emailField.value,
              code: otpField.value,
            },
          })
        );
      }
    }
  }
}
