import { Injectable } from '@angular/core';
import {
  CaseDetailsModel,
  CaseModel,
  CaseTypeSummary,
} from '@app/core/models/case.model';
import { OptionModel } from '@app/core/models/option.model';
import { DataverseService } from '@app/core/services/azure-functions/dataverse.service';
import { SUPPORT_TYPES } from '@app/shared/app.constants';
import { CaseActions } from '@app/store/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class CaseEffects {
  constructor(
    private actions$: Actions,
    private dataverseService: DataverseService
  ) {}
  getCases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CaseActions.getCases),
      switchMap(({ requestBody }) =>
        this.dataverseService.getCases(requestBody).pipe(
          map((allCases: CaseModel[]) =>
            CaseActions.getCasesSuccess({ allCases })
          ),
          catchError(err =>
            of(CaseActions.getCasesFailure({ errorMsg: err.message }))
          )
        )
      )
    )
  );

  getCaseTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CaseActions.getCaseTypes),
      switchMap(() =>
        this.dataverseService.caseTypeGet().pipe(
          map((caseTypes: OptionModel[]) => {
            // create requested display name changes
            for (let i = 0; i < caseTypes.length; i += 1) {
              let caseDisplayName = null;

              switch (caseTypes[i].id) {
                case SUPPORT_TYPES.MISSED_SERVICE: {
                  caseDisplayName = 'Missed Service';
                  break;
                }
                case SUPPORT_TYPES.DAMAGED_EQUIPMENT: {
                  caseDisplayName = 'Damaged/Replace Equipment';
                  break;
                }
                case SUPPORT_TYPES.SUSTAINIBILITY: {
                  caseDisplayName = 'Sustainability';
                  break;
                }
              }
              caseTypes[i].displayName = caseDisplayName;
            }
            return CaseActions.getCaseTypesSuccess({ caseTypes });
          }),
          catchError(err =>
            of(CaseActions.getCaseTypesFailure({ errorMsg: err.message }))
          )
        )
      )
    )
  );

  getCaseStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CaseActions.getCaseStatuses),
      switchMap(() =>
        this.dataverseService.caseStatusGet().pipe(
          map((caseStatuses: OptionModel[]) =>
            CaseActions.getCaseStatusesSuccess({ caseStatuses })
          ),
          catchError(err =>
            of(CaseActions.getCaseStatusesFailure({ errorMsg: err.message }))
          )
        )
      )
    )
  );

  createCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CaseActions.createCase),
      switchMap(({ newCase }) =>
        this.dataverseService.createCase(newCase).pipe(
          map((response: any) => {
            const createdCaseNumber = response.caseNumber;

            return CaseActions.createCaseSuccess({ createdCaseNumber });
          }),
          catchError(err =>
            of(CaseActions.createCaseFailure({ errorMsg: err.message }))
          )
        )
      )
    )
  );

  createCasePublic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CaseActions.createCasePublic),
      switchMap(({ newCase }) =>
        this.dataverseService.caseCreatePublic(newCase).pipe(
          map((response: any) => {
            const createdCaseNumber = response.caseNumber;

            return CaseActions.createCaseSuccess({ createdCaseNumber });
          }),
          catchError(err =>
            of(CaseActions.createCaseFailure({ errorMsg: err.error }))
          )
          // of(CaseActions.createCaseFailure({ errorMsg: err.message }))),
        )
      )
    )
  );

  createCaseFollowUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CaseActions.createCaseFollowUp),
      switchMap(({ caseFollowUp }) =>
        this.dataverseService.createCaseFollowUp(caseFollowUp).pipe(
          map((response: any) => {
            const createdCaseNumber = response.caseNumber;

            return CaseActions.createCaseFollowUpSuccess({ createdCaseNumber });
          }),
          catchError(err =>
            of(CaseActions.createCaseFollowUpFailure({ errorMsg: err.message }))
          )
        )
      )
    )
  );

  getCaseDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CaseActions.getCaseDetails),
      switchMap(({ caseNumber }) =>
        this.dataverseService.getCaseDetails(caseNumber).pipe(
          map((response: CaseDetailsModel[]) => {
            const selectedCaseDetails = response.pop();

            if (selectedCaseDetails) {
              return CaseActions.getCaseDetailsSuccess({ selectedCaseDetails });
            }
            return CaseActions.getCaseDetailsFailure({
              errorMsg: `Couldn't find the case(${caseNumber}) you're looking for.`,
            });
          }),
          catchError(err =>
            of(CaseActions.getCaseDetailsFailure({ errorMsg: err.error }))
          )
        )
      )
    )
  );

  getCaseTypeSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CaseActions.getCaseTypeSummary),
      switchMap(({ requestBody }) =>
        this.dataverseService.getCasesTypeSummary(requestBody).pipe(
          map((caseTypeSummary: CaseTypeSummary[]) =>
            CaseActions.getCaseTypeSummarySuccess({ caseTypeSummary })
          ),
          catchError(err =>
            of(CaseActions.getCaseTypeSummaryFailure({ errorMsg: err.message }))
          )
        )
      )
    )
  );
}
