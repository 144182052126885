import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-multi-select-autocomplete',
  templateUrl: './multi-select-autocomplete.component.html',
  styleUrls: ['./multi-select-autocomplete.component.scss'],
})
export class MultiSelectAutocompleteComponent implements OnInit {
  @Input() options: string[] = [];
  @Input() label: string = 'Select';
  @Input() optionsWidth?: number = undefined;
  @Output() selectionChange = new EventEmitter<string[]>();

  formControl = new FormControl();
  filteredOptions!: Observable<string[]>;
  selectedOptions: string[] = [];
  lastFilter: string = '';

  ngOnInit(): void {
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith<string | string[]>(''),
      map(value => (typeof value === 'string' ? value : this.lastFilter)),
      map(filter => this.filterOptions(filter))
    );
  }

  private filterOptions(filter: string): string[] {
    this.lastFilter = filter;
    if (filter) {
      return this.options.filter(option =>
        option.toLowerCase().includes(filter.toLowerCase())
      );
    }
    return this.options.slice();
  }

  displayFn(value: string[] | string): string | undefined {
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    return value;
  }

  optionClicked(event: Event, option: string): void {
    event.stopPropagation();
    this.toggleSelection(option);
  }

  toggleSelection(option: string): void {
    const index = this.selectedOptions.indexOf(option);

    if (index === -1) {
      this.selectedOptions.push(option);
    } else {
      this.selectedOptions.splice(index, 1);
    }

    this.selectionChange.emit(this.selectedOptions);

    this.formControl.setValue(this.selectedOptions);
  }
}
