import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CaseActions } from '@app/store';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-support-ticket-submit-modal',
  templateUrl: './support-ticket-submit-modal.component.html',
  styleUrls: ['./support-ticket-submit-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupportTicketSubmitModalComponent implements OnInit {
  caseNumber: string;
  rerouteUrl: string;
  demoRequest: boolean = false;
  subscriptions: Subscription = new Subscription();
  constructor(
    private store: Store<AppState>,
    private router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.caseNumber = this.data.caseNumber;
    this.rerouteUrl = this.data.rerouteUrl;
    this.demoRequest = this.data.demoRequest;
  }

  reRouteOnClick() {
    // clear out local store before routing back to support ticket list
    this.store.dispatch(CaseActions.clearCases());
    this.router.navigate([this.rerouteUrl], {
      queryParams: this.data.queryParams ? this.data.queryParams : undefined,
    });

    this.store.dispatch(CaseActions.clearCreatedCaseNumber());
  }
}
