import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchInputComponent implements OnChanges {
  @Input() searchText = '';
  @Input() placeholder = 'Search';
  @Output() inputValue = new EventEmitter<string>();
  @Input() readonly = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    // if searchText Input exists, set the input field and emit the value to parent
    if (changes.searchText) {
      this.inputValue.emit(changes.searchText.currentValue);
    }
  }

  clearSearch() {
    this.searchText = '';
    this.inputValue.emit(this.searchText);
  }

  handleKeyUp() {
    this.inputValue.emit(this.searchText);
  }
}
