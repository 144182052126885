import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SurveyData } from '@app/core/models/survey-settings.model';

@Injectable({ providedIn: 'root' })
export class SurveyDataService extends AzureBaseService {
  constructor(protected http: HttpClient) {
    super(http, '/func-rts-b2c-user-management-');
  }

  getSurveyDetails(): Observable<SurveyData[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/survey`;

    return this.http.get<HttpResponse<any>>(requestUrl, this.httpOptions).pipe(
      map(response => {
        const surveyList = JSON.parse(response.body);

        return surveyList as SurveyData[];
      })
    );
  }
}
