import { TwilioActions } from '@app/store';
import { createReducer, on } from '@ngrx/store';

export const twilioFeatureKey = 'twilio';

export interface TwilioState {
  isPhoneValidationSent?: boolean;
  isPhoneValidated?: boolean;
  isEmailValidationSent?: boolean;
  isEmailValidated?: boolean;
  errorMsg?: any;
}

const initialState: TwilioState = {
  isPhoneValidationSent: false,
  isPhoneValidated: false,
  isEmailValidationSent: false,
  isEmailValidated: false,
  errorMsg: null,
};

export const twilioReducer = createReducer(
  initialState,
  on(TwilioActions.verifyPhoneNumberSuccess, (state, { response }) => ({
    ...state,
    isPhoneValidationSent: response.isValid,
    errorMsg: response.errors,
  })),
  on(TwilioActions.verifyPhoneNumberFailure, (state, { errorMsg }) => ({
    ...state,
    isPhoneValidationSent: false,
    errorMsg: errorMsg,
  })),
  on(TwilioActions.verifyEmailSuccess, (state, { response }) => ({
    ...state,
    isEmailValidationSent: response.isValid,
    errorMsg: response.errors,
  })),
  on(TwilioActions.verifyEmailFailure, (state, { errorMsg }) => ({
    ...state,
    isEmailValidationSent: false,
    errorMsg: errorMsg,
  })),
  on(TwilioActions.verifyOTPSuccess, (state, { response }) => {
    if (response.channel === 'sms') {
      return {
        ...state,
        isPhoneValidated: response.isValid,
        errorMsg: response.errors,
      };
    }

    if (response.channel === 'email') {
      return {
        ...state,
        isEmailValidated: response.isValid,
        errorMsg: response.errors,
      };
    }
  }),
  on(TwilioActions.verifyOTPFailure, (state, { errorMsg }) => ({
    ...state,
    isPhoneValidated: false,
    errorMsg: errorMsg,
  }))
);
