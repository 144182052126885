import {
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '@app/store/app.state';
import {
  AuthSelectors,
  ContentfulSelectors,
  RouterSelectors,
  UserDataSelectors,
} from '@app/store/selectors';
import { User } from '@app/core/models/auth-user';
import {
  UsernameDisplayPipe,
  UserInitialsDisplayPipe,
} from '@app/shared/pipes/username-display.pipe';
import { PageLink } from '@app/core/models/page-link';
import { Router } from '@angular/router';
import { ContentfulModel } from '@app/core/models/contentful.model';
import { MsalService } from '@azure/msal-angular';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
  providers: [UsernameDisplayPipe, UserInitialsDisplayPipe],
  encapsulation: ViewEncapsulation.None,
})
export class TopNavComponent implements OnInit, OnDestroy {
  @Input() pages: PageLink[];
  user$: Observable<User> = this.store.select(AuthSelectors.getUser);
  billingAccounts$ = this.store.select(
    UserDataSelectors.getUserBillingAccounts
  );
  hasSeenWhatsNew$: Observable<boolean> = this.store.select(
    ContentfulSelectors.hasSeenWhatsNew
  );
  whatsNew$: Observable<ContentfulModel> = this.store.select(
    ContentfulSelectors.getWhatsNew
  );
  subscriptions: Subscription = new Subscription();
  screenWidth: number;
  activeMenu: string = '';
  activeSubItem: string = '';
  activeSubItemsList: string = '';
  dropdownNavItems = [
    {
      name: 'Settings',
      routerLink: '/account',
    },
  ];
  routerState$: Observable<SerializedRouterStateSnapshot> = this.store.select(
    RouterSelectors.getRouterState
  );

  constructor(
    private store: Store<AppState>,
    private router: Router,
    public auth: MsalService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit() {
    this.screenWidth = window.innerWidth;

    // Fix language translator button style
    this.subscriptions.add(
      this.user$.subscribe(user => {
        if (user && user.name.length) {
          setTimeout(() => {
            if (this.screenWidth > 768) {
              const width =
                document.getElementById('user-name').offsetWidth + 70 - 100;

              const eles = document.getElementsByClassName(
                'wg-default'
              ) as HTMLCollectionOf<HTMLElement>;

              for (let i = 0; i < eles.length; i += 1) {
                eles[i].style.setProperty('right', width + 'px', 'important');
              }
            }
          }, 2000);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  redirectToWhatsNew(): void {
    this.router.navigate(
      ['account/account-information', { whatsNewShowing: true }],
      { fragment: 'whatsNew' }
    );
  }

  showPageSubLink(event, menuName: string) {
    event.stopPropagation();
    if (this.activeMenu === menuName) {
      this.activeMenu = '';
    } else this.activeMenu = menuName;
  }

  activeSubMenu(subItem: string) {
    if (this.activeSubItem === subItem) {
      this.activeSubItem = '';
    } else this.activeSubItem = subItem;
  }

  showSubItem(event, subItem: string) {
    event.stopPropagation();
    if (this.activeSubItemsList === subItem) {
      this.activeSubItemsList = '';
    } else this.activeSubItemsList = subItem;
  }

  logout(e) {
    this.auth.logout({ postLogoutRedirectUri: this.doc.location.origin });
    // allows auth to actually end user session and redirect
    // without immediately logging the user back in
    e.preventDefault();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }
}
