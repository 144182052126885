import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AuthSelectors,
  GlobalSelectors,
  RouterSelectors,
  SiteActions,
} from '@app/store';
import { AppState } from '@app/store/app.state';
import { Subscription } from 'rxjs';
import { CP_PERMISSIONS } from '@app/shared/app.constants';
import { switchMap, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit, OnDestroy {
  showBackButton: boolean;
  hideSupportButtons: boolean;
  subNavTitle = '';
  subscriptions: Subscription = new Subscription();

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.getRouterState();
    this.subscriptions.add(
      this.store
        .select(AuthSelectors.getUserPermissions)
        .pipe(
          switchMap(userPermissions =>
            this.store.select(GlobalSelectors.getSelectedGlobalFilters).pipe(
              filter(
                selectedBillingAcc =>
                  userPermissions.includes(
                    CP_PERMISSIONS.SITES_SITEINFO_READ
                  ) && selectedBillingAcc.billingAccounts.length > 0
              ),
              distinctUntilChanged((prev, curr) => {
                return JSON.stringify(prev) === JSON.stringify(curr);
              })
            )
          )
        )
        .subscribe(selectedBillingAcc => {
          this.store.dispatch(
            SiteActions.getSites({ requestBody: selectedBillingAcc })
          );
          this.store.dispatch(
            SiteActions.allAccountServiceSchedulesStartLoading()
          );
          this.store.dispatch(
            SiteActions.getAllAccountServiceSchedules({
              requestBody: selectedBillingAcc,
            })
          );
        })
    );
  }

  getRouterState() {
    this.store
      .select(RouterSelectors.getRouterState)
      .subscribe((state: any) => {
        const data = state?.data;

        this.showBackButton = data?.showBackButton;
        this.hideSupportButtons = data?.hideSupportButtons;
        // we use the "title" to manage the active state on the navigation
        // AND show a title for the page in the sub nav
        // alternateTitle is just to change the display name
        this.subNavTitle = data?.alternateTitle
          ? data?.alternateTitle
          : data?.title;
      });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
