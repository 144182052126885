import {
  CaseDetailsModel,
  CaseModel,
  CaseTypeSummary,
} from '@app/core/models/case.model';
import { OptionModel } from '@app/core/models/option.model';
import { CaseActions } from '@app/store/actions';
import { createReducer, on } from '@ngrx/store';

export const caseFeatureKey = 'case';

export interface CaseState {
  allCases: CaseModel[];
  casesError: string | null;
  caseTypes: OptionModel[];
  caseStatuses: OptionModel[];
  createdCaseNumber: string | null;
  caseCreationError: string | null;
  caseDetailsMap: { [caseNumber: string]: CaseDetailsModel };
  createdCaseFollowUp: string;
  caseFollowUpCreationError?: string;
  caseTypeSummary: CaseTypeSummary[];
  caseTypeSummaryError: string | null;
  casesLoading: boolean;
  caseStatusLoading: boolean;
  caseDetailsError: string | null;
}

const initialState: CaseState = {
  allCases: [],
  casesError: null,
  caseTypes: [],
  caseStatuses: [],
  createdCaseNumber: null,
  caseCreationError: null,
  caseDetailsMap: {},
  createdCaseFollowUp: null,
  caseFollowUpCreationError: null,
  caseTypeSummary: [],
  caseTypeSummaryError: null,
  casesLoading: true,
  caseStatusLoading: true,
  caseDetailsError: null,
};

export const caseReducer = createReducer(
  initialState,
  on(CaseActions.getCasesSuccess, (state, { allCases }) => ({
    ...state,
    casesLoading: false,
    allCases,
  })),
  on(CaseActions.getCasesFailure, (state, { errorMsg }) => ({
    ...state,
    casesLoading: false,
    casesError: errorMsg,
  })),
  on(CaseActions.getCaseTypesSuccess, (state, { caseTypes }) => ({
    ...state,
    caseTypes,
  })),
  on(CaseActions.getCaseStatusesSuccess, (state, { caseStatuses }) => ({
    ...state,
    caseStatuses,
  })),
  on(CaseActions.createCaseSuccess, (state, { createdCaseNumber }) => ({
    ...state,
    createdCaseNumber,
  })),
  on(CaseActions.createCaseFailure, (state, { errorMsg }) => ({
    ...state,
    caseCreationError: errorMsg,
  })),
  on(CaseActions.clearCaseCreationError, state => ({
    ...state,
    caseCreationError: null,
  })),
  on(CaseActions.clearCreatedCaseNumber, state => ({
    ...state,
    createdCaseNumber: null,
  })),
  on(CaseActions.clearCases, state => ({
    ...state,
    allCases: [],
  })),
  on(CaseActions.createCaseFollowUpSuccess, (state, { createdCaseNumber }) => ({
    ...state,
    createdCaseFollowUp: createdCaseNumber,
  })),
  on(CaseActions.createCaseFollowUpFailure, (state, { errorMsg }) => ({
    ...state,
    caseFollowUpCreationError: errorMsg,
  })),
  on(CaseActions.resetCaseFollowUpNumber, state => ({
    ...state,
    createdCaseFollowUp: null,
  })),
  on(CaseActions.clearCaseFollowUpError, state => ({
    ...state,
    caseFollowUpCreationError: null,
  })),
  on(CaseActions.getCaseDetailsSuccess, (state, { selectedCaseDetails }) => ({
    ...state,
    caseDetailsMap: {
      ...state.caseDetailsMap,
      [selectedCaseDetails.caseNumber]: selectedCaseDetails,
    },
    caseDetailsError: null,
  })),

  on(CaseActions.getCaseDetailsFailure, (state, { errorMsg }) => ({
    ...state,
    caseDetailsError: errorMsg,
  })),

  on(CaseActions.getCaseTypeSummarySuccess, (state, { caseTypeSummary }) => ({
    ...state,
    caseStatusLoading: false,
    caseTypeSummary,
  })),
  on(CaseActions.getCaseTypeSummaryFailure, (state, { errorMsg }) => ({
    ...state,
    caseStatusLoading: false,
    caseTypeSummaryError: errorMsg,
  })),
  on(CaseActions.casesStartLoading, state => ({
    ...state,
    casesLoading: true,
  })),

  on(CaseActions.caseStatusStartLoading, state => ({
    ...state,
    caseStatusLoading: true,
  })),
  on(CaseActions.caseStatusStopLoading, state => ({
    ...state,
    caseStatusLoading: false,
  }))
);
