import { ServiceSchedule } from '@app/core/api-azure-functions/dataverse-functions';
import { OnDemandProductModel } from '@app/core/models/on-demand-product.model';
import { OptionModel } from '@app/core/models/option.model';
import {
  ActiveSiteModel,
  CustomerInvoices,
  SiteDetailsModel,
  SiteHaulerContractDocuments,
  SiteModel,
} from '@app/core/models/site.model';
import { BillingAccountsReqObj } from '@app/core/models/UserDataModel';
import { createAction, props } from '@ngrx/store';

export const getSite = createAction(
  '[Site] Get Site',
  props<{ customerId: string; siteId: string }>()
);

export const currentSiteStartLoading = createAction(
  '[Case] Current Site Start Loading'
);

export const getSiteSuccess = createAction(
  '[Site] Get Site Success',
  props<{ currentSite: SiteDetailsModel }>()
);

export const getSiteFailure = createAction(
  '[Site] Get Site Failure',
  props<{ errorMsg: string }>()
);

export const sitesStartLoading = createAction('[Case] Start Sites Loading');

export const sitesStopLoading = createAction('[Case] Stop Sites Loading');

export const getSites = createAction(
  '[Site] Get Sites',
  props<{ requestBody: BillingAccountsReqObj }>()
);

export const getSitesSuccess = createAction(
  '[Site] Get Sites Success',
  props<{ allSites: SiteModel[] }>()
);

export const getSitesFailure = createAction(
  '[Site] Get Sites Failure',
  props<{ errorMsg: string }>()
);

// site list table state
export const setSiteListTableState = createAction(
  '[Site List] Set table state',
  props<{ searchText: string; pageNumber: number }>()
);

export const resetSiteDetails = createAction('[Site] Reset Site Details');

// get all services associated with 1 site
export const getServiceSchedules = createAction(
  '[Site] Get Service Schedules',
  props<{
    tenantId: string;
    siteId: string;
    requestBody: BillingAccountsReqObj;
  }>()
);

export const currentServiceSchedulesStartLoading = createAction(
  '[Case] Current Service Schedules Start Loading'
);

export const getServiceSchedulesSuccess = createAction(
  '[Site] Get Service Schedules Success',
  props<{ currentServiceSchedules: ServiceSchedule[] }>()
);

export const getServiceSchedulesFailure = createAction(
  '[Site] Get Service Schedules Failure',
  props<{ errorMsg: string }>()
);

// Service Schedule Details
export const getServiceScheduleDetails = createAction(
  '[Site] Get Service Schedule Details',
  props<{ siteId: string }>()
);

export const getServiceScheduleDetailsFailure = createAction(
  '[Site] Get Service Schedule Details Failure',
  props<{ errorMsg: string }>()
);

// get all services associated with the account(all of the account sites combined)
export const allAccountServiceSchedulesStartLoading = createAction(
  '[Account] All Account Service Schedules Start Loading'
);

export const getAllAccountServiceSchedules = createAction(
  '[Account] Get All Account Service Schedules',
  props<{ requestBody: BillingAccountsReqObj }>()
);

export const getAllAccountServiceSchedulesSuccess = createAction(
  '[Account] Get All Account Service Schedules Success',
  props<{ allAccountServiceSchedules: ServiceSchedule[] }>()
);

export const getAllAccountServiceSchedulesFailure = createAction(
  '[Account] Get All Account Service Schedules Failure',
  props<{ errorMsg: string }>()
);

export const getEquipmentTypes = createAction('[Site] Get Equipment Types');

export const getEquipmentTypesSuccess = createAction(
  '[Site] Get Equipment Types Success',
  props<{ equipmentTypes: OptionModel[] }>()
);

export const getEquipmentTypesFailure = createAction(
  '[Site] Get Equipment Types Failure',
  props<{ errorMsg: string }>()
);

export const getEquipmentSizes = createAction('[Site] Get Equipment Sizes');

export const getEquipmentSizesSuccess = createAction(
  '[Site] Get Equipment Sizes Success',
  props<{ equipmentSizes: OptionModel[] }>()
);

export const getEquipmentSizesFailure = createAction(
  '[Site] Get Equipment Sizes Failure',
  props<{ errorMsg: string }>()
);

// get all rts "on-demand" products
export const getOnDemandProducts = createAction(
  '[Site] Get On Demand Products'
);

export const getOnDemandProductsSuccess = createAction(
  '[Site] Get On Demand Products Success',
  props<{ onDemandProducts: OnDemandProductModel[] }>()
);

export const getOnDemandProductsFailure = createAction(
  '[Site] Get On Demand Products Failure',
  props<{ errorMsg: string }>()
);

// Active sites
export const getActiveSites = createAction(
  '[Site] Get Active Sites',
  props<{ billingAccountIds: string[] }>()
);

export const getActiveSitesSuccess = createAction(
  '[Site] Get Active Sites Success',
  props<{ allActiveSites: ActiveSiteModel[] }>()
);

export const getActiveSitesFailure = createAction(
  '[Site] Get Active Sites Failure',
  props<{ errorMsg: string }>()
);

// Site Hauler Contract Documents
export const getSiteHaulerContractDocuments = createAction(
  '[Site] Get Site Hauler Contract Documents',
  props<{ siteId: string }>()
);

export const getSiteHaulerContractDocumentsSuccess = createAction(
  '[Site] Get Site Hauler Contract Documents Success',
  props<{ siteHaulerContractDocuments: SiteHaulerContractDocuments[] }>()
);

export const getSiteHaulerContractDocumentsFailure = createAction(
  '[Site] Get Site Hauler Contract Documents Failure',
  props<{ errorMsg: string }>()
);

// Invoices by site
export const getCustomerInvoicesBySite = createAction(
  '[Site] Get customer Invoices By Site',
  props<{ customerId: string; customerNumber: string }>()
);

export const getCustomerInvoicesSuccess = createAction(
  '[Site] Get Customer Invoices Success',
  props<{ customerInvoices: CustomerInvoices[] }>()
);

export const getCustomerInvoicesFailure = createAction(
  '[Site] Get Customer Invoices Failure',
  props<{ errorMsg: string }>()
);
