import { InvoiceResponse } from '@app/core/api';
import {
  InvoiceDetail,
  InvoiceFile,
} from '@app/core/api-azure-functions/dataverse-functions/model/invoice';
import { BillingAccountsReqObj } from '@app/core/models/UserDataModel';
import { createAction, props } from '@ngrx/store';

// Invoice list
export const getInvoices = createAction(
  '[Invoices] Get Invoices',
  props<{ requestBody: BillingAccountsReqObj }>()
);

export const getInvoicesSuccess = createAction(
  '[Invoices] Get Invoices Success',
  props<{ invoices: InvoiceResponse[] }>()
);
export const getInvoicesLoading = createAction(
  '[Invoices] Get Invoices Loading'
);
export const setInvoicesListTableState = createAction(
  '[Site List] Set invoice table state',
  props<{ searchText: string; pageNumber: number }>()
);

export const getInvoicesFailure = createAction(
  '[Invoices] Get Invoices Failure',
  props<{ errorMsg: string }>()
);

// Invopice Details
export const getInvoiceDetails = createAction(
  '[Invoices] Get Invoice Details',
  props<{ billingAccountId: string; invoiceId: string }>()
);

export const getInvoiceDetailsSuccess = createAction(
  '[Invoices] Get Invoice Details Success',
  props<{ invoiceDetails: InvoiceDetail }>()
);

export const getInvoiceDetailsFailure = createAction(
  '[Invoices] Get Invoice Details Failure',
  props<{ errorMsg: string }>()
);

export const resetInvoiceDetails = createAction(
  '[Invoices] Reset Invoice Details'
);

// Invoice File
export const getInvoiceFile = createAction(
  '[Invoices] Get Invoice File',
  props<{ region: 'usa1' | 'usa2'; fileName: string; invoiceType: string }>()
);

export const getInvoiceFileSuccess = createAction(
  '[Invoices] Get Invoice File Success',
  props<{ invoiceFile: InvoiceFile }>()
);

export const getInvoiceFileFailure = createAction(
  '[Invoices] Get Invoice File Failure',
  props<{ errorMsg: string }>()
);
export const invoiceFileLoadingStart = createAction(
  '[Invoices] Invoice File Start Loading'
);

// Invoice File for Embed the
export const getInvoiceFileToEmbed = createAction(
  '[Invoices] Get Invoice File To Embed',
  props<{ region: 'usa1' | 'usa2'; fileName: string; invoiceType: string }>()
);

export const getInvoiceFileToEmbedSuccess = createAction(
  '[Invoices] Get Invoice File To Embed Success',
  props<{ invoiceFileToEmbed: string }>()
);

export const getInvoiceFileToEmbedFailure = createAction(
  '[Invoices] Get Invoice File To Embed Failure',
  props<{ errorMsg: string }>()
);

export const resetInvoiceFileToEmbed = createAction(
  '[Invoices] Reset Invoice File To Embed'
);
