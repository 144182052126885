import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDataModel, Notification } from '@app/core/models/UserDataModel';

@Injectable({ providedIn: 'root' })
export class UserDataService extends AzureBaseService {
  constructor(protected http: HttpClient) {
    super(http, '/func-rts-b2c-user-management-');
  }

  userGetByAdId(adObjectId: string): Observable<UserDataModel> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/users/${adObjectId}`;

    return this.http.get<HttpResponse<any>>(requestUrl, this.httpOptions).pipe(
      map(response => {
        const user = JSON.parse(response.body)[0];

        return user as UserDataModel;
      })
    );
  }

  userUpdateById(user: UserDataModel): Observable<UserDataModel> {
    const requestUrl =
      this.azureBaseUrl + this.functionUri + this.azureEnv + '/user/profile';

    return this.http
      .put<HttpResponse<any>>(requestUrl, user, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as UserDataModel));
  }

  userSaveLoggedInTime(adObjectId: string): Observable<boolean> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/user/${adObjectId}/logtime`;

    return this.http
      .put<HttpResponse<any>>(requestUrl, adObjectId, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as boolean));
  }

  userUpdateAcccountSettingsById(
    user: UserDataModel
  ): Observable<UserDataModel> {
    const requestUrl =
      this.azureBaseUrl +
      this.functionUri +
      this.azureEnv +
      '/user/account-settings';

    return this.http
      .put<HttpResponse<any>>(requestUrl, user, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as UserDataModel));
  }

  userUpdateNotifSettingsById(
    adObjectId: string,
    notificationSettings: Notification
  ): Observable<UserDataModel> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/user/notification-settings/${adObjectId}`;

    return this.http
      .put<HttpResponse<any>>(
        requestUrl,
        notificationSettings,
        this.httpOptions
      )
      .pipe(map(response => JSON.parse(response.body) as UserDataModel));
  }
}
