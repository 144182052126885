import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent implements OnInit, OnDestroy {
  surveyURL: SafeResourceUrl;
  subscriptions: Subscription = new Subscription();
  height: string = '493';
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );

  constructor(
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { surveyLink: string },
    private sanitizer: DomSanitizer,
    private breakpointObserver: BreakpointObserver
  ) {
    // Sanitize the chosen URL
    this.surveyURL = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.data.surveyLink
    );
  }

  ngOnInit() {
    const viewportHeight = window.innerHeight;
    const iframeHeight = viewportHeight * 0.8;

    this.height = `${iframeHeight}px`;

    this.subscriptions.add(
      this.isExtraSmall.subscribe(size => {
        if (size.matches) {
          this.height = `${window.innerHeight - 20.8}`;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
