<div>
  <app-error></app-error>
  <app-side-nav
    *ngIf="isUserLoggedIn"
    [pages]="pages"
    [isSideNavOpen]="isSideNavOpen"
    class="d-none d-md-flex">
  </app-side-nav>

  <div
    class="{{ isSideNavOpen ? 'desktop-nav-open' : '' }}"
    [ngClass]="{
      'authed-margin': isUserLoggedIn,
      'app-wrapper': isUserLoggedIn
    }">
    <div class="nav-wrapper" *ngIf="isUserLoggedIn">
      <div class="side-nav-toggle" (click)="onSideNavToggle()">
        <img
          src="{{
            isSideNavOpen
              ? 'assets/images/menu.svg'
              : 'assets/images/menu-expand.svg'
          }}"
          alt="Menu Open" />
      </div>
      <app-top-nav [pages]="pages"></app-top-nav>
    </div>

    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<app-spinner *ngIf="showLoadingVisual$ | async"></app-spinner>
