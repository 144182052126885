<mat-sidenav-container class="side-nav-container-wrapper">
  <mat-sidenav
    #leftSidenav
    mode="side"
    opened
    [fixedInViewport]="true"
    [fixedTopGap]="0"
    [fixedBottomGap]="0">
    <div
      class="side-nav-container {{ isSideNavOpen ? 'desktop-nav-open' : '' }}">
      <div class="nav-logo">
        <a [routerLink]="'/dashboard'">
          <img
            src="assets/images/rts-logo-white.png"
            alt="Recycle Track Systems" />
        </a>
      </div>
      <ul class="nav-items">
        <ng-container *ngFor="let page of pages">
          <li
            *appFeature="page.featureName"
            class="nav-list-item {{
              (routerState$ | async)?.data.parent === page.name
                ? 'page-active'
                : (routerState$ | async)?.data.title === page.name
                  ? 'page-active'
                  : ''
            }}">
            <a
              [routerLink]="
                page.pageSubLink && page.pageSubLink.length > 0
                  ? null
                  : page.routerLink
              "
              (click)="showPageSubLink(page.name)">
              <div
                class="nav-icon"
                [style.mask]="
                  'url(' + page.icon + ') no-repeat center / contain'
                "
                [style.-webkit-mask]="
                  'url(' + page.icon + ') no-repeat center / contain'
                "></div>
              <span
                id="spanMenu"
                [@animateText]="isSideNavOpen ? 'show' : 'hide'"
                >{{ page.name }}
              </span>
              <mat-icon
                fontIcon="{{
                  activecMenu === page.name ? 'expand_less' : 'expand_more'
                }}"
                *ngIf="page.pageSubLink && page.pageSubLink.length > 0">
              </mat-icon>
            </a>
            <!-- Sub-Category -->
            <div *ngIf="activecMenu === page.name">
              <ul
                *ngIf="page.pageSubLink && page.pageSubLink.length > 0"
                class="nav-sub-items">
                <li
                  *ngFor="let subPageItem of page.pageSubLink"
                  class="nav-sub-list-item">
                  <a (click)="showSubItem(subPageItem.subItemName)">
                    <span
                      id="{{
                        subPageItem.subItemName === activeSubItemsList
                          ? 'spanMenu'
                          : ''
                      }}"
                      [@animateText]="isSideNavOpen ? 'show' : 'hide'"
                      >{{ subPageItem.subItemName }}
                    </span>
                    <mat-icon
                      fontIcon="{{
                        activeSubItemsList === subPageItem.subItemName
                          ? 'expand_less'
                          : 'expand_more'
                      }}"
                      *ngIf="
                        subPageItem.subItem && subPageItem.subItem.length > 0
                      ">
                    </mat-icon>
                  </a>
                  <!-- Sab Category Item -->
                  <div *ngIf="activeSubItemsList === subPageItem.subItemName">
                    <ul
                      *ngIf="
                        subPageItem.subItem && subPageItem.subItem.length > 0
                      ">
                      <div *ngFor="let child of subPageItem.subItem">
                        <li
                          *appSabFeature="{
                            featureName: child.featureName,
                            featureType: subPageItem.featureName,
                            id: child.id
                          }"
                          class="nav-sub-category-item">
                          <a
                            [routerLink]="child.routerLink"
                            (click)="activeSubMenu(child.name)">
                            <span
                              id="{{
                                child.name === activeSubItem ? 'spanMenu' : ''
                              }}"
                              [@animateText]="isSideNavOpen ? 'show' : 'hide'">
                              {{ child.name }}
                            </span>
                          </a>
                        </li>
                      </div>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ng-container>
        <li class="nav-list-item">
          <a (click)="logout($event)" class="dropdown-nav-item">
            <div class="nav-icon log-out-icon" alt="Log Out"></div>
            <span [@animateText]="isSideNavOpen ? 'show' : 'hide'"
              >Log Out</span
            >
          </a>
        </li>
      </ul>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
