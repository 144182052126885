import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupportPublicService {
  private selectedCase = new Subject();
  public selectedCaseObservable = this.selectedCase.asObservable();

  private wasteStreamOptions: { value: string; text: string }[] = [
    { text: 'Cardboard OCC', value: 'Cardboard OCC' },
    { text: 'Single-stream', value: 'Single-stream' },
    { text: 'Trash', value: 'Trash' },
    { text: 'Recycle Yard Waste', value: 'Recycle Yard Waste' },
    { text: 'Organics', value: 'Organics' },
    { text: 'Construction Debris', value: 'Construction Debris' },
    { text: 'Wood', value: 'Wood' },
    { text: 'Animal Bedding', value: 'Animal Bedding' },
    { text: 'Bulk Waste Pickup', value: 'Bulk Waste Pickup' },
    { text: 'Commingled Recycling', value: 'Commingled Recycling' },
    { text: 'Composting', value: 'Composting' },
    { text: 'E-Waste', value: 'E-Waste' },
    { text: 'Hard Drive Destruction', value: 'Hard Drive Destruction' },
    { text: 'Hazardous Waste Disposal', value: 'Hazardous Waste Disposal' },
    { text: 'Medical Waste Disposal', value: 'Medical Waste Disposal' },
    { text: 'Paper Recycling', value: 'Paper Recycling' },
    {
      text: 'Secure Document Destruction',
      value: 'Secure Document Destruction',
    },
    { text: 'Secure Paper Shredding', value: 'Secure Paper Shredding' },
    { text: 'Snow', value: 'Snow' },
    { text: 'Universal Waste', value: 'Universal Waste' },
    { text: 'Unknown', value: 'Unknown' },
  ];

  private equipmentTypeOptions: {
    value: string;
    text: string;
    options: string[];
  }[] = [
    {
      text: 'Bags',
      value: 'Bags',
      options: ['1/2 yd', '1 yd', '32 gal', '64 gal', '96 gal'],
    },
    { text: 'Bales', value: 'Bales', options: ['1/2 yd', '1 yd'] },
    {
      text: 'Front Load Compactor',
      value: 'Front Load Compactor',
      options: ['2 yd', '4 yd', '6 yd', '8 yd'],
    },
    {
      text: 'Front Load Container',
      value: 'Front Load Container',
      options: ['2 yd', '4 yd', '6 yd', '8 yd', '10 yd'],
    },
    { text: 'Hamper', value: 'Hamper', options: ['1/2 yd'] },
    { text: 'Lock', value: 'Lock', options: ['N/A'] },
    {
      text: 'Mini Container',
      value: 'Mini Container',
      options: ['1/2 yd', '1 yd'],
    },
    { text: 'Pallet', value: 'Pallet', options: ['1 yd'] },
    { text: 'Puck', value: 'Puck', options: ['N/A'] },
    {
      text: 'Rear Load Compactor',
      value: 'Rear Load Compactor',
      options: ['2 yd', '3 yd', '4 yd', '6 yd', '8 yd'],
    },
    {
      text: 'Rear Load Container',
      value: 'Rear Load Container',
      options: ['1/2 yd', '1 yd', '2 yd', '3 yd', '4 yd', '6 yd', '8 yd'],
    },
    {
      text: 'Roll Off Compactor',
      value: 'Roll Off Compactor',
      options: ['15yd', '20yd', '25yd', '30yd', '35yd'],
    },
    {
      text: 'Roll Off Container',
      value: 'Roll Off Container',
      options: ['15yd', '20yd', '25yd', '30yd', '35yd'],
    },
    {
      text: 'Roll off open top',
      value: 'Roll off open top',
      options: ['10 yd', '15 yd', '20 yd', '30 yd', '40 yd'],
    },
    {
      text: 'Totes',
      value: 'Totes',
      options: ['16 gal', '32 gal', '64 gal', '96 gal'],
    },
    {
      text: 'Trailer',
      value: 'Trailer',
      options: ['53 ft'],
    },
  ];

  private states: { value: string; text: string }[] = [
    { value: 'AK', text: 'Alaska' },
    { value: 'AL', text: 'Alabama' },
    { value: 'AR', text: 'Arkansas' },
    { value: 'AZ', text: 'Arizona' },
    { value: 'CA', text: 'California' },
    { value: 'CO', text: 'Colorado' },
    { value: 'CT', text: 'Connecticut' },
    { value: 'DC', text: 'District of Columbia' },
    { value: 'DE', text: 'Delaware' },
    { value: 'FL', text: 'Florida' },
    { value: 'GA', text: 'Georgia' },
    { value: 'HI', text: 'Hawaii' },
    { value: 'IA', text: 'Iowa' },
    { value: 'ID', text: 'Idaho' },
    { value: 'IL', text: 'Illinois' },
    { value: 'IN', text: 'Indiana' },
    { value: 'KS', text: 'Kansas' },
    { value: 'KY', text: 'Kentucky' },
    { value: 'LA', text: 'Louisiana' },
    { value: 'MA', text: 'Massachusetts' },
    { value: 'MD', text: 'Maryland' },
    { value: 'ME', text: 'Maine' },
    { value: 'MI', text: 'Michigan' },
    { value: 'MN', text: 'Minnesota' },
    { value: 'MO', text: 'Missouri' },
    { value: 'MS', text: 'Mississippi' },
    { value: 'MT', text: 'Montana' },
    { value: 'NC', text: 'North Carolina' },
    { value: 'ND', text: 'North Dakota' },
    { value: 'NE', text: 'Nebraska' },
    { value: 'NH', text: 'New Hampshire' },
    { value: 'NJ', text: 'New Jersey' },
    { value: 'NM', text: 'New Mexico' },
    { value: 'NV', text: 'Nevada' },
    { value: 'NY', text: 'New York' },
    { value: 'OH', text: 'Ohio' },
    { value: 'OK', text: 'Oklahoma' },
    { value: 'OR', text: 'Oregon' },
    { value: 'PA', text: 'Pennsylvania' },
    { value: 'RI', text: 'Rhode Island' },
    { value: 'SC', text: 'South Carolina' },
    { value: 'SD', text: 'South Dakota' },
    { value: 'TN', text: 'Tennessee' },
    { value: 'TX', text: 'Texas' },
    { value: 'UT', text: 'Utah' },
    { value: 'VA', text: 'Virginia' },
    { value: 'VT', text: 'Vermont' },
    { value: 'WA', text: 'Washington' },
    { value: 'WI', text: 'Wisconsin' },
    { value: 'WV', text: 'West Virginia' },
    { value: 'WY', text: 'Wyoming' },
  ];

  private canadianProvinces: { value: string; text: string }[] = [
    { value: 'AB', text: 'Alberta' },
    { value: 'BC', text: 'British Columbia' },
    { value: 'MB', text: 'Manitoba' },
    { value: 'NB', text: 'New Brunswick' },
    { value: 'NL', text: 'Newfoundland and Labrador' },
    { value: 'NS', text: 'Nova Scotia' },
    { value: 'NT', text: 'Northwest Territories' },
    { value: 'NU', text: 'Nunavut' },
    { value: 'ON', text: 'Ontario' },
    { value: 'PE', text: 'Prince Edward Island' },
    { value: 'QC', text: 'Québec' },
    { value: 'SK', text: 'Saskatchewan' },
    { value: 'YT', text: 'Yukon' },
  ];

  constructor() {}

  getStates(country: string) {
    return country.toLowerCase() === 'us'
      ? this.states
      : this.canadianProvinces;
  }

  getWasteStreamOptions() {
    return this.wasteStreamOptions;
  }

  getEquipmentTypeOptions() {
    return this.equipmentTypeOptions;
  }

  onSelect(value: any) {
    this.selectedCase.next(value);
  }
}
