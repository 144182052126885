<div class="file-upload {{ showValidationError ? ' validation-error' : '' }}">
  <div class="file-upload-label">
    {{ label }}<span *ngIf="isRequired"> *</span>
  </div>
  <div
    class="upload-container"
    appDragAndDrop
    (fileDropped)="onFileDropped($event)"
    (onFileWarningMessage)="showFileWarningMessage($event)">
    <!-- Disable input if the maximum number of files is reached -->
    <input
      type="file"
      [accept]="allowedFileTypes.join(', ')"
      #fileDropRef
      id="fileDropRef"
      (change)="fileBrowseHandler($event)"
      [disabled]="files.length >= maxFiles"
      multiple />
    <img src="assets/images/upload.svg" alt="Image Upload" />
    <div class="drag-and-drop-label">
      <label for="fileDropRef" *ngIf="files.length < maxFiles">
        Drag and drop files here
      </label>
      <div *ngIf="files.length < maxFiles">or</div>
      <div class="label-button" *ngIf="files.length < maxFiles">
        Browse for files
      </div>
    </div>
  </div>
  <div class="upload-messages">
    <div class="error-message">
      {{ fileWarningMessage }}
    </div>
    <div class="supported-file-types" *ngIf="!fileWarningMessage">
      Supports {{ allowedFileTypes.join(', ') }}
    </div>
  </div>
</div>

<div class="files-list">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <img src="assets/images/description.svg" alt="File" />
    <div class="info">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <p class="size">
        {{ formatBytes(file?.size) }}
      </p>
      <app-progress-bar [progress]="file?.progress"></app-progress-bar>
    </div>
    <img
      class="delete"
      (click)="deleteFile(i)"
      src="assets/images/delete.svg"
      alt="Delete" />
  </div>
</div>
