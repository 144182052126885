import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SurveyActions } from '@app/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SurveyDataService } from '@app/core/services/azure-functions/survey-settings.service';
import { SurveyData } from '@app/core/models/survey-settings.model';

@Injectable()
export class SurveyDataEffects {
  constructor(
    private actions$: Actions,
    private surveyService: SurveyDataService
  ) {}

  getSurveySettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SurveyActions.getSurveySettings),
      switchMap(() =>
        from(this.surveyService.getSurveyDetails()).pipe(
          map((survey: SurveyData[]) =>
            SurveyActions.getSurveySettingsSuccess({ survey })
          ),
          catchError(err =>
            of(
              SurveyActions.getSurveySettingsFailure({
                errorMsg: err.message(),
              })
            )
          )
        )
      )
    )
  );
}
