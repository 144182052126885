import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store/app.state';
import { AnalyticsSelectors } from '@app/store/selectors';
import { filter } from 'rxjs/operators';

export interface Feature {
  featureName: string;
  featureType: string;
  id?: string;
}

@Directive({ selector: '[appSabFeature]' })
export class SabFeatureDirective {
  constructor(
    private store: Store<AppState>,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set appSabFeature(feature: Feature) {
    if (feature.featureType.toLowerCase() === 'generalreport') {
      this.store
        .select(AnalyticsSelectors.getReportList)
        .pipe(filter(reportsList => reportsList.length > 0))
        .subscribe(reportsList => {
          this.viewContainer.clear();
          const reportCategory = reportsList.filter(
            n =>
              n.reportCategory.toLowerCase() ===
              feature.featureName.toLowerCase()
          );

          if (reportCategory.length > 0) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
        });
    } else if (feature.featureType.toLowerCase() === 'advancereport') {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
