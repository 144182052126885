import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input() showValidationError: boolean = false;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() isRequired: boolean = false;
  @Input() maxFiles: number = 1; // Max no of files allowed
  @Output() onFileChange = new EventEmitter<File[] | File>();
  fileWarningMessage: string | null = null;
  files: File[] = [];
  allowedFileTypes: string[] = [
    '.bmp',
    '.jpg',
    '.jpeg',
    '.png',
    '.heic',
    '.heics',
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.txt',
  ];

  constructor() {}

  onFileDropped(files: File[]) {
    this.prepareFilesList(files);
  }

  fileBrowseHandler($event: Event) {
    const target = $event.target as HTMLInputElement;

    if (target.files) {
      this.prepareFilesList(Array.from(target.files));
    }
  }

  showFileWarningMessage(warningMessage: string) {
    this.fileWarningMessage = warningMessage;
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.onFileChange.emit(this.maxFiles === 1 ? this.files[0] : this.files);
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      }

      const progressInterval = setInterval(() => {
        if ((this.files[index] as any).progress === 100) {
          clearInterval(progressInterval);
          this.uploadFilesSimulator(index + 1);
        } else {
          (this.files[index] as any).progress += 20;
        }
      }, 200);
    }, 500);
  }

  prepareFilesList(files: Array<File>) {
    this.fileWarningMessage = null;

    for (const file of files) {
      if (this.files.length >= this.maxFiles) {
        this.fileWarningMessage = `You can upload a maximum of ${this.maxFiles} file(s).`;
        break;
      }

      if (this.isValidFileType(file.name)) {
        (file as any).progress = 0; // Add a progress property
        this.files.push(file);
      } else {
        this.fileWarningMessage = `Invalid file type: ${
          file.name
        }. Supported types are ${this.allowedFileTypes.join(', ')}`;
      }
    }
    this.onFileChange.emit(this.maxFiles === 1 ? this.files[0] : this.files);
    this.uploadFilesSimulator(0);
  }

  isValidFileType(fileName: string): boolean {
    const fileExtension = fileName
      .substring(fileName.lastIndexOf('.'))
      .toLowerCase();

    return this.allowedFileTypes.includes(fileExtension);
  }

  formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i];
  }
}
