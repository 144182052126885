import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WorkOrderState } from '@app/store';
import { WorkOrderDetailsModel } from '@app/core/models/workorder.model';

export const selectWorkOrderSlice =
  createFeatureSelector<WorkOrderState>('workorder');

export const getCurrentWorkOrder = createSelector(
  selectWorkOrderSlice,
  (state: WorkOrderState) => state.currentWorkOrder
);

export const getCurrentWorkOrderForMap = createSelector(
  getCurrentWorkOrder,
  (currentWorkOrder: WorkOrderDetailsModel) => {
    const statusMap = {
      'Open - Unscheduled': 'Unscheduled',
      'Open - Scheduled': 'Scheduled',
      'Open - In Progress': 'In Progress',
      'Open - Completed': 'Completed',
      'Closed - Posted': 'Completed',
      'Closed - Canceled': 'Cancelled',
    };

    return {
      customerId: currentWorkOrder.customerId,
      workOrderId: currentWorkOrder.workOrderId,
      workOrderNumber: currentWorkOrder.workOrderNumber,
      systemStatus:
        statusMap[currentWorkOrder.systemStatus] ||
        currentWorkOrder.systemStatus,
      requestDate: currentWorkOrder.requestDate,
      dumpSlipRequired: currentWorkOrder.dumpSlipRequired,
      workOrderSummary: currentWorkOrder.workOrderSummary,
      effectiveDate: currentWorkOrder.effectiveDate,
      requestType: currentWorkOrder.requestType,
      equipmentName: currentWorkOrder.equipmentName,
      serviceAccountName: currentWorkOrder.serviceAccountName,
      totalEstimatedDuration: currentWorkOrder.totalEstimatedDuration,
      confirmedServiceDate: currentWorkOrder.confirmedServiceDate,
      products: currentWorkOrder.products,
      serviceDetails: currentWorkOrder.serviceDetails,
    };
  }
);

export const getAllWorkOrder = createSelector(
  selectWorkOrderSlice,
  (sites: WorkOrderState) => sites.allWorkOrder
);

export const getAllWorkOrderWithProducts = createSelector(
  selectWorkOrderSlice,
  (sites: WorkOrderState) => sites.allWorkOrdersWithProducts
);

export const isCurrentWorkOrderLoading = createSelector(
  selectWorkOrderSlice,
  (state: WorkOrderState) => state.currentWorkOrderLoading
);
