import {
  PowerBiReport,
  PowerBiReportDetails,
  RegionSite,
  Report,
  ReportPostData,
  ReportRegion,
} from '@app/core/models/analytics.model';
import { createAction, props } from '@ngrx/store';

export const getReportList = createAction('[Analytics] Get Report List');

export const showReportsListLoading = createAction(
  '[Analytics] Show Reports List loading'
);

export const getReportListSuccess = createAction(
  '[Analytics] Get Report List Success',
  props<{ reportList: Report[] }>()
);

export const getReportListFailure = createAction(
  '[Analytics] Get Report List Failure',
  props<{ errorMsg: string }>()
);

export const getReportToken = createAction(
  '[Analytics] Get Report Token',
  props<{ requestBody: ReportPostData }>()
);

export const getReportTokenSuccess = createAction(
  '[Analytics] Get Report Token Success',
  props<{ reportToken: string }>()
);

export const getReportTokenFailure = createAction(
  '[Analytics] Get Report Token Failure',
  props<{ errorMsg: string }>()
);

export const resetReportToken = createAction('[Analytics] Reset Report Token');

export const createAnalyticDemoRequest = createAction(
  '[Analytics] Create Analytic Demo Request',
  props<{ email: string }>()
);

export const resetAnalyticDemoRequest = createAction(
  '[Analytics] Reset Analytic Demo Request'
);

export const createAnalyticDemoRequestSuccess = createAction(
  '[Analytics] Create Analytic Demo Request Success'
);

export const createAnalyticDemoRequestFailure = createAction(
  '[Analytics] Create Analytic Demo Request Failure',
  props<{ errorMsg: string }>()
);

export const getReportRegionByBillingAccount = createAction(
  '[Analytics] Get Report Region By Billing Account',
  props<{
    requestBody: {
      billingAccountGuid: string;
    };
  }>()
);

export const getReportRegionListSuccess = createAction(
  '[Analytics] Get Report Region List Success',
  props<{ reportRegionList: ReportRegion[] }>()
);

export const getReportRegionListFailure = createAction(
  '[Analytics] Get Report Region List Failure',
  props<{ errorMsg: string }>()
);

export const getSitesByRegion = createAction(
  '[Analytics] Get sites By Region',
  props<{
    requestBody: {
      regionId: number;
    };
  }>()
);

export const getRegionSiteListSuccess = createAction(
  '[Analytics] Get Site List By Region Success',
  props<{ regionSiteList: RegionSite[] }>()
);

export const getRegionSiteListFailure = createAction(
  '[Analytics] Get Site List By Region Failure',
  props<{ errorMsg: string }>()
);

export const getPowerBiReportDetails = createAction(
  '[Analytics] Get Power Bi Report details',
  props<{ requestBody: PowerBiReport }>()
);

export const getPowerBiReportDetailsSuccess = createAction(
  '[Analytics] Get Power Bi Report Details Success',
  props<{ powerBiReportDetails: PowerBiReportDetails }>()
);

export const getPowerBiReportDetailsFailure = createAction(
  '[Analytics] Get Power Bi Report Details Failure',
  props<{ errorMsg: string }>()
);

export const resetPowerBiReportDetails = createAction(
  '[Analytics] Reset Power Bi Report Details'
);
