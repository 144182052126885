import { ContainerDeviceResponse } from '@app/core/api';
import { SequoiaDeviceModel } from '@app/core/models/sequoia-device.model';
import { SequoiaFullnessVisualizationModel } from '@app/core/models/sequoia-fullness-visualization.model';
import { createAction, props } from '@ngrx/store';

export const showPelloDevicesLoading = createAction(
  '[ContainerDevices] Show Pello Devices loading'
);

export const showSequoiaDevicesLoading = createAction(
  '[ContainerDevices] Show Sequoia Devices loading'
);

export const getSequoiaDevices = createAction(
  '[ContainerDevices] Get Sequoia Device Data',
  props<{ requestBody: string[] }>()
);

export const getSequoiaDevicesSuccess = createAction(
  '[ContainerDevices] Get Sequoia Device Data Success',
  props<{ allSequoiaDevices: SequoiaDeviceModel[] }>()
);

export const getSequoiaDevicesFailure = createAction(
  '[ContainerDevices] Get Sequoia Device Data Failure',
  props<{ errorMsg: string }>()
);

export const getSequoiaDeviceLoggedFullness = createAction(
  '[ContainerDevices] Get Sequoia Device Logged Fullness',
  props<{ id: number; startDate: string; endDate: string }>()
);

export const getSequoiaDeviceLoggedFullnessSuccess = createAction(
  '[ContainerDevices] Get Sequoia Device Logged Fullness Success',
  props<{ sequoiaLoggedFullness: SequoiaFullnessVisualizationModel[] }>()
);

export const getSequoiaDeviceLoggedFullnessFailure = createAction(
  '[ContainerDevices] Get Sequoia Device Logged Fullness Failure',
  props<{ errorMsg: string }>()
);

export const getSequoiaDeviceForecastedFullness = createAction(
  '[ContainerDevices] Get Sequoia Device Forecasted Fullness',
  props<{ id: number }>()
);

export const getSequoiaDeviceForecastedFullnessSuccess = createAction(
  '[ContainerDevices] Get Sequoia Device Forecasted Fullness Success',
  props<{ sequoiaForecastedFullness: SequoiaFullnessVisualizationModel[] }>()
);

export const getSequoiaDeviceForecastedFullnessFailure = createAction(
  '[ContainerDevices] Get Sequoia Device Forecasted Fullness Failure',
  props<{ errorMsg: string }>()
);

export const resetSequoiaDeviceData = createAction(
  '[ContainerDevices] Reset Sequoia Device Data'
);

export const getPelloDevices = createAction(
  '[ContainerDevices] Get Pello Device Data',
  props<{ requestBody: string[] }>()
);

export const getPelloDevicesSuccess = createAction(
  '[ContainerDevices] Get Pello Device Data Success',
  props<{ allPelloDevices: ContainerDeviceResponse[] }>()
);

export const getPelloDevicesFailure = createAction(
  '[ContainerDevices] Get Pello Device Data Failure',
  props<{ errorMsg: string }>()
);

export const resetPelloDevicesData = createAction(
  '[ContainerDevices] Reset Pello Devices Data'
);

export const createDemoRequest = createAction(
  '[ContainerDevices] Create Demo Request',
  props<{ email: string }>()
);

export const resetDemoRequest = createAction(
  '[ContainerDevices] Reset Demo Request'
);

export const createDemoRequestSuccess = createAction(
  '[ContainerDevices] Create Demo Request Success'
);

export const createDemoRequestFailure = createAction(
  '[ContainerDevices] Create Demo Request Failure',
  props<{ errorMsg: string }>()
);
