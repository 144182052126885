export const environment = {
   production: 'true',
   azureAppInsightsSubscriptionKey: '0a9eead4-ce31-4e8a-9e63-8dbff7f5e89d',
   adB2CTenant: 'rtscustomers',
   adB2CClientId: '117f8599-a95c-4163-9af6-f3df01886a63',
   azureCustomerPortalAPIMGatewayBaseUrl: 'https://apim-dev-eastus-003.azure-api.net/',
   azureEnvironment: 'dev',
   contentfulSpaceId: 'da20uarnwvzo',
   contentfulToken: 'X4ilwgww3pcIBSnTp6zZq0qGdj2e6iqhzZ_3Pd0fM0Q',
   contentfulEnvironment: 'dev',
   stripeKey: 'pk_test_EANjjiLA5Vwo8IrQBKurJTpD',
   elytusStripeKey:'pk_test_51P96p9BH851Wdd3t1zrNkpTzZX5SVVFJyIY5PLKPwnEPPbkuBNLu3ESihHXQ5QPA92dwWdIgPZAD9CqBkbO53pIH006HYvr1yG',
   customerServiceEmail: 'customerservice@rts.com',
   recaptchaKey: '6LcE2W4nAAAAAKeNmCUtBkeHXfFfxN-CVvs49Mfe',
   chatbotUrl: 'https://web.powerva.microsoft.com/environments/55d2d6e6-ed2a-47e0-b1d3-d13cd0ce311a/bots/cr62a_caseStatus/webchat?__version__=2',
};
