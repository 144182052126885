<div
  id="{{ controlName }}-input-container"
  [formGroup]="parentForm"
  [ngClass]="showErrors || hint ? 'mb-2' : null">
  <mat-form-field class="text-input" [appearance]="appearance">
    <mat-label>{{ label ? label : placeholder }}</mat-label>
    <span *ngIf="matPrefix" matPrefix>{{ matPrefix }}&nbsp;</span>
    <input
      matInput
      id="{{ controlName }}-input"
      [formControlName]="controlName"
      [type]="type"
      [placeholder]="placeholder"
      [min]="min"
      [max]="max"
      [errorStateMatcher]="errorStateMatcher"
      [readonly]="readonly" />
    <button
      matSuffix
      mat-flat-button
      type="button"
      (click)="onButtonClick()"
      *ngIf="hasMatSuffix"
      [matTooltip]="suffixTooltip"
      matTooltipPosition="above"
      [color]="suffixColor"
      [disabled]="matSuffixDisabled">
      {{ suffixText }}
    </button>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error
      *ngIf="
        showErrors &&
        !parentForm.controls[controlName].hasError('required') &&
        parentForm.controls[controlName].errors
      ">
      Please enter a valid {{ label ? label : placeholder }}
    </mat-error>
    <mat-error
      *ngIf="
        showErrors && parentForm.controls[controlName].hasError('required')
      ">
      {{ label ? label : placeholder }} is <strong>required</strong>
    </mat-error>
  </mat-form-field>
</div>
