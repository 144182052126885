import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CorporateState, SitesState } from '@app/store';
import { ServiceSchedule } from '@app/core/api-azure-functions/dataverse-functions';
import { GoogleMapLocation } from '@app/core/models/google-map-location.model';
import { selectCorporateAccountSlice } from '@app/store/selectors/corporate.selectors';
import { OnDemandProductModel } from '@app/core/models/on-demand-product.model';
import { SiteDetailsModel, SiteModel } from '@app/core/models/site.model';

export const selectSitesSlice = createFeatureSelector<SitesState>('sites');

export const getCurrentSite = createSelector(
  selectSitesSlice,
  (state: SitesState) => state.currentSite
);

export const getCurrentSiteForMap = createSelector(
  getCurrentSite,
  (currentSite: SiteDetailsModel) => [
    {
      customerId: currentSite.customerId,
      accountId: currentSite.accountId,
      latitude: currentSite.latitude,
      longitude: currentSite.longitude,
      name: currentSite.name,
      address1: currentSite.address1,
      address2: currentSite.address2 ? currentSite.address2 : '',
      city: currentSite.city,
      state: currentSite.state,
      zip: currentSite.zip,
    } as GoogleMapLocation,
  ]
);

export const getAllSites = createSelector(
  selectSitesSlice,
  (sites: SitesState) => sites.allSites
);

export const getAllActiveSites = createSelector(
  selectSitesSlice,
  (sites: SitesState) => {
    // make sure sites exist first
    if (sites.allSites) {
      // only return active sites
      return sites.allSites.filter(
        (site: SiteModel) => site.status === 'Active'
      );
    }
  }
);

export const getAllActiveSitesForMap = createSelector(
  getAllActiveSites,
  (sites: SiteDetailsModel[]) => {
    if (sites) {
      return sites.map(
        (site: SiteDetailsModel) =>
          ({
            customerId: site.customerId,
            accountId: site.accountId,
            latitude: site.latitude,
            longitude: site.longitude,
            name: site.name,
            address1: site.address1,
            address2: site.address2 ? site.address2 : '',
            city: site.city,
            state: site.state,
            zip: site.zip,
          }) as GoogleMapLocation
      );
    }
  }
);

export const getAllSiteAccountNumbers = createSelector(
  selectSitesSlice,
  (sites: SitesState) => {
    if (sites.allSites) {
      const filteredSites = sites.allSites.filter(
        (site: SiteModel) => site.status === 'Active'
      );
      const filteredAccountNumbers = filteredSites.map(a => a.accountNumber);

      // turns an array into a set filtering out duplicates
      // then puts it back into an array
      return [...new Set(filteredAccountNumbers)];
    }
  }
);

export const getCorporateAccountNumber = createSelector(
  selectCorporateAccountSlice,
  (corporateAccount: CorporateState) => {
    if (corporateAccount.corporateAccount) {
      return [corporateAccount.corporateAccount.accountNumber];
    }
  }
);

// combines the billing/corporate acct number with the individual sites account numbers
export const getAllAccountNumbers = createSelector(
  getAllSiteAccountNumbers,
  getCorporateAccountNumber,
  (siteAccountNumbers, corporateAccountNumber) => {
    if (siteAccountNumbers && corporateAccountNumber) {
      return [...siteAccountNumbers, ...corporateAccountNumber];
    }
  }
);

export const getSiteTableState = createSelector(
  selectSitesSlice,
  (sites: SitesState) => sites.siteTableState
);

export const getAllActiveServiceSchedules = createSelector(
  selectSitesSlice,
  (sites: SitesState) =>
    sites.allAccountServiceSchedules.filter(
      (serviceSchedule: ServiceSchedule) =>
        serviceSchedule.status === 'Active' &&
        (serviceSchedule.debris !== 'Unknown' ||
          serviceSchedule.isRecurringService !== false ||
          serviceSchedule.accountServiceEquipment !== null ||
          (serviceSchedule.isRecurringService &&
            serviceSchedule.repeat !== 'Weekly'))
    )
);

export const getAllActiveServiceSchedulesForCalendar = createSelector(
  selectSitesSlice,
  (sites: SitesState) => {
    return sites.allAccountServiceSchedules
      .filter(
        (serviceSchedule: ServiceSchedule) =>
          serviceSchedule.status === 'Active'
      )
      .map(
        ({
          customerId,
          accountId,
          effectiveDate,
          expirationDate,
          isRecurringService,
          recurringFrequency,
          sunday,
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          accountName,
          debris,
          accountServiceEquipment,
        }) => ({
          customerId,
          accountId,
          effectiveDate,
          expirationDate,
          isRecurringService,
          recurringFrequency,
          sunday,
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          accountName,
          debris,
          accountServiceEquipment,
        })
      );
  }
);

export const getCurrentActiveServiceSchedulesRecurring = createSelector(
  selectSitesSlice,
  (sites: SitesState) =>
    sites.currentServiceSchedules.filter(
      (serviceSchedule: ServiceSchedule) =>
        serviceSchedule.status === 'Active' &&
        serviceSchedule.isRecurringService
    )
);

export const getCurrentActiveServiceSchedulesOnCall = createSelector(
  selectSitesSlice,
  (sites: SitesState) =>
    sites.currentServiceSchedules.filter(
      (serviceSchedule: ServiceSchedule) =>
        serviceSchedule.status === 'Active' &&
        !serviceSchedule.isRecurringService
    )
);

export const getCurrentInactiveServiceSchedules = createSelector(
  selectSitesSlice,
  (sites: SitesState) =>
    sites.currentServiceSchedules.filter(
      (serviceSchedule: ServiceSchedule) =>
        serviceSchedule.status === 'Inactive'
    )
);

export const getEquipmentTypes = createSelector(
  selectSitesSlice,
  (sites: SitesState) => sites.equipmentTypes
);

export const getEquipmentSizes = createSelector(
  selectSitesSlice,
  (sites: SitesState) => sites.equipmentSizes
);

// return the displayName for both the value and text
// we're currently not using the productId
export const getOnDemandProducts = createSelector(
  selectSitesSlice,
  (sites: SitesState) => {
    if (sites.onDemandProducts) {
      // return value/text obj for dropdown
      const onDemandOptions = sites.onDemandProducts.map(
        (onDemandProduct: OnDemandProductModel) =>
          ({
            value: onDemandProduct.DisplayName,
            text: onDemandProduct.DisplayName,
          }) as { value: string; text: string }
      );

      // sort alphabetically
      return [...onDemandOptions].sort((a, b) => {
        const nameA = a.value.toLowerCase();
        const nameB = b.value.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    }
  }
);

export const isSitesLoading = createSelector(
  selectSitesSlice,
  (state: SitesState) => state.sitesLoading
);

export const isCurrentSiteLoading = createSelector(
  selectSitesSlice,
  (state: SitesState) => state.currentSiteLoading
);

export const isCurrentServiceSchedulesLoading = createSelector(
  selectSitesSlice,
  (state: SitesState) => state.currentServiceSchedulesLoading
);

export const isAllServiceSchedulesLoading = createSelector(
  selectSitesSlice,
  (state: SitesState) => state.allAccountServiceSchedulesLoading
);

export const getAllActiveSitesForSelectedBillinAccs = createSelector(
  selectSitesSlice,
  (sites: SitesState) => sites.allActiveSites
);

export const getSiteHaulerContractDocuments = createSelector(
  selectSitesSlice,
  (sites: SitesState) => sites.siteHaulerContractDocuments
);

export const getCustomerInvoices = createSelector(
  selectSitesSlice,
  (sites: SitesState) => sites.customerInvoices
);

export const isCustomerInvoicesLoading = createSelector(
  selectSitesSlice,
  (state: SitesState) => state.customerInvoicesLoading
);
