import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent {
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() type: string = 'text';
  @Input() label: string;
  @Input() placeholder: string;
  @Input() min: string = null;
  @Input() max: string = null;
  @Input() appearance: string = 'outline';
  @Input() showErrors: boolean = false;
  @Input() errorStateMatcher: ErrorStateMatcher = null;
  @Input() hint: string = null;
  @Input() readonly: boolean;
  @Input() required: boolean = false;

  @Input() matPrefix: string = undefined;
  @Input() hasMatSuffix: boolean = false;
  @Input() matSuffixDisabled: boolean;
  @Input() suffixText: string = 'text';
  @Input() suffixColor: string = 'accent';
  @Input() suffixTooltip: string = '';
  @Output() onMatButtonClick = new EventEmitter<any>();

  constructor() {}

  onButtonClick() {
    if (this.hasMatSuffix) {
      this.onMatButtonClick.emit({
        control: this.parentForm.controls[this.controlName],
        name: this.controlName,
      });
    }
  }
}
