/**
 * OpenAPI Document on Azure Functions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface WorkOrder {
  id?: string;
  customerId?: string;
  accountId?: string;
  createdBy?: string;
  createdDate?: string;
  updatedBy?: string;
  updatedDate?: string;
  workOrderId?: string;
  workOrderNumber?: string;
  scheduleAId?: string;
  agreementId?: string;
  workOrderTypeId?: string;
  equipmentId?: string;
  serviceAccountId?: string;
  requestTypeId?: string;
  functionalLocationId?: string;
  haulerId?: string;
  caseId?: string;
  status?: WorkOrder.StatusEnum;
  subStatusId?: string;
  taxable?: boolean;
  systemStatus?: WorkOrder.SystemStatusEnum;
  requestDate?: string;
  dumpSlipRequired?: boolean;
  workOrderSummary?: string;
  haulerStatus?: WorkOrder.HaulerStatusEnum;
  serviceDetails?: string | null;
}

export namespace WorkOrder {
  export type StatusEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  export const StatusEnum = {
    NUMBER_0: 0 as StatusEnum,
    NUMBER_1: 1 as StatusEnum,
    NUMBER_2: 2 as StatusEnum,
    NUMBER_3: 3 as StatusEnum,
    NUMBER_4: 4 as StatusEnum,
    NUMBER_5: 5 as StatusEnum,
    NUMBER_6: 6 as StatusEnum,
    NUMBER_7: 7 as StatusEnum,
    NUMBER_8: 8 as StatusEnum,
    NUMBER_9: 9 as StatusEnum,
    NUMBER_10: 10 as StatusEnum,
  };
  export type SystemStatusEnum =
    | 690970000
    | 690970001
    | 690970002
    | 690970003
    | 690970004
    | 690970005;
  export const SystemStatusEnum = {
    NUMBER_690970000: 690970000 as SystemStatusEnum,
    NUMBER_690970001: 690970001 as SystemStatusEnum,
    NUMBER_690970002: 690970002 as SystemStatusEnum,
    NUMBER_690970003: 690970003 as SystemStatusEnum,
    NUMBER_690970004: 690970004 as SystemStatusEnum,
    NUMBER_690970005: 690970005 as SystemStatusEnum,
  };
  export type HaulerStatusEnum = 0 | 1 | 2;
  export const HaulerStatusEnum = {
    NUMBER_0: 0 as HaulerStatusEnum,
    NUMBER_1: 1 as HaulerStatusEnum,
    NUMBER_2: 2 as HaulerStatusEnum,
  };
}
