import { createReducer, on } from '@ngrx/store';
import { SurveyActions } from '@app/store';
import { SurveyData } from '@app/core/models/survey-settings.model';

export const surveyFeatureKey = 'survey';

export interface SurveyState {
  survey: SurveyData[] | null;
}

const initialState: SurveyState = {
  survey: null,
};

export const SurveyReducer = createReducer(
  initialState,
  on(SurveyActions.getSurveySettingsSuccess, (state, { survey }) => ({
    ...state,
    survey,
  }))
);
