import { Injectable } from '@angular/core';
import { AnalyticsService } from '@app/core/services/azure-functions/analytics.service';
import { AnalyticsActions } from '@app/store/actions';
import {
  PowerBiReportDetails,
  RegionSite,
  Report,
  ReportRegion,
} from '@app/core/models/analytics.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SendEmailService } from '@app/core/services/azure-functions/send-email.service';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class AnalyticsEffects {
  constructor(
    private actions$: Actions,
    private analyticsService: AnalyticsService,
    private sendEmailService: SendEmailService
  ) {}

  getReportsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getReportList),
      switchMap(() =>
        this.analyticsService.getReportsList().pipe(
          map((reportList: Report[]) =>
            AnalyticsActions.getReportListSuccess({ reportList })
          ),
          catchError(err =>
            of(AnalyticsActions.getReportListFailure({ errorMsg: err.message }))
          )
        )
      )
    )
  );

  getReportToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getReportToken),
      switchMap(({ requestBody }) =>
        this.analyticsService.getReportToken(requestBody).pipe(
          map((reportToken: string) =>
            AnalyticsActions.getReportTokenSuccess({ reportToken })
          ),
          catchError(err =>
            of(
              AnalyticsActions.getReportTokenFailure({ errorMsg: err.message })
            )
          )
        )
      )
    )
  );

  createAnalyticDemoRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.createAnalyticDemoRequest),
      switchMap(({ email }) =>
        this.sendEmailService.sendAnalyticsDemoRequest(email).pipe(
          map((response: HttpResponse<any>) => {
            if (response.status === 200) {
              return AnalyticsActions.createAnalyticDemoRequestSuccess();
            }
          }),
          catchError(err =>
            of(
              AnalyticsActions.createAnalyticDemoRequestFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );

  getReportRegionByBillingAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getReportRegionByBillingAccount),
      switchMap(({ requestBody }) =>
        this.analyticsService.getReportRegionByBillingAccount(requestBody).pipe(
          map((reportRegionList: ReportRegion[]) =>
            AnalyticsActions.getReportRegionListSuccess({ reportRegionList })
          ),
          catchError(err =>
            of(
              AnalyticsActions.getReportRegionListFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );

  getSitesByRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getSitesByRegion),
      switchMap(({ requestBody }) =>
        this.analyticsService.getSitesByRegion(requestBody).pipe(
          map((regionSiteList: RegionSite[]) =>
            AnalyticsActions.getRegionSiteListSuccess({ regionSiteList })
          ),
          catchError(err =>
            of(
              AnalyticsActions.getRegionSiteListFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );

  getPowerBiReportDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActions.getPowerBiReportDetails),
      switchMap(({ requestBody }) =>
        this.analyticsService.getPowerBiReportDetails(requestBody).pipe(
          map((powerBiReportDetails: PowerBiReportDetails) =>
            AnalyticsActions.getPowerBiReportDetailsSuccess({
              powerBiReportDetails,
            })
          ),
          catchError(err =>
            of(
              AnalyticsActions.getPowerBiReportDetailsFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );
}
